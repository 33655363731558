import React, { useState } from "react";
import "./style.css";
import "./styleCard.css";
import { colors, makeStyles } from "@material-ui/core";
import { useSnackbar } from "notistack";
import SectionMain from "../SectionMain";
import curando_magoas from "../../assets/images/thumbnails/videos/Marcello_Cotrim_Curando_Magoas.jpg";
import ButtonMedita from "../ButtonMedita";
import api from "../../core/api";
import ModalInfoPlan from "./components/ModalInfoPlan";
import ModalInfoLogin from "./components/ModalInfoLogin";

const useStyles = makeStyles({
  border: {
    marginRight: 10,
    borderRadius: 10,
  },
  form: {
    width: "80%",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: "20px",
  },
  p: {
    fontWeight: "600",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  pSpan: {
    fontWeight: "600",
    marginBottom: 0,
    textAlign: "left",
  },
  pInfos: {
    fontWeight: "300",
    marginBottom: 0,
    textAlign: "center",
    color: "#030102",
    fontSize: "12px",
    padding: 25,
  },
  stepCard: {
    display: "flex",
    marginTop: "15px",
  },
  modalCancel: {
    width: "500px",
    background: "#fff",
    margin: "200px auto",
    borderRadius: "13px",
    padding: "20px",
    textAlign: "center",
  },
  titleNotice: {
    fontFamily: "Century Gothic",
    fontSize: 13,
    fontWeight: "normal",
    fontStyle: "normal",
    textAlign: "center",
    color: "#183B56",
  },
  bold: { fontWeight: "bold" },
  containerSeven: {
    paddingBottom: "5%",
    position: "absolute",
    left: 0,
  },
  leftSectionSeven: {
    maxWidth: "90%",
    margin: "auto",
    marginTop: 50,
  },
  titleSectionSeven: {
    fontFamily: "Century Gothic",
    fontSize: "36px",
    fontWeight: "normal",
    fontStyle: "normal",
    textAlign: "center",
    color: "#3bad76",
    marginTop: 10,
  },
  descriptionSeven: {
    fontFamily: "Century Gothic",
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
    textAlign: "left",
    color: "#5a7084",
  },
  sealView: {
    paddingLeft: 20,
    paddingRight: 20,
    backgroundColor: "#E1F3EC",
    borderRadius: 40,
    width: "fit-content",
    margin: "auto",
  },
  sealText: {
    fontFamily: "Century Gothic",
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
    textAlign: "center",
    color: "#36B37E",
  },
  card: {
    height: "200px",
    width: "250px",
    backgroundImage: ({ thumbnail }) => `url(${thumbnail})`,
    borderRadius: 30,
    backgroundSize: "cover",
    backgroundPosition: "center",
    justifyContent: "flex-end",
    flexDirection: "column",
  },
  cardBigger: {
    height: "260px",
    width: "300px",
    backgroundImage: ({ thumbnail }) => `url(${thumbnail})`,
    borderRadius: 30,
    backgroundSize: "cover",
    backgroundPosition: "center",
    justifyContent: "flex-end",
    flexDirection: "column",
  },
});

const StepOneCard = ({ nextStep, setForm }) => {
  const token = localStorage.getItem("token");
  const [form, setFormUser] = useState({
    name: "",
    email: "",
    text: "",
    description: "GIFT",
  });
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalLoginIsOpen, setLoginIsOpen] = React.useState(false);
  const [valid, setValid] = useState(true);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const CheckPlanUser = async () => {
    try {
      await api
        .post("/giftcards/exists", { email: form.email })
        .then(async (res) => {
          if (form.name === "" || form.email === "" || form.text === "")
            return setValid(false);

          if (
            !res.data.premium ||
            res.data.premium === "N" ||
            res.data.premium === "F"
          ) {
            if (!token) return setLoginIsOpen(true);
            setForm(form);
            nextStep();
          } else {
            setIsOpen(true);
          }
        });
    } catch (e) {
      console.error({ e });
      enqueueSnackbar(`${e.response.data.message}` || "Ocorreu um erro", {
        variant: "error",
        anchorOrigin: {
          horizontal: "right",
          vertical: "top",
        },
      });
      return false;
    }
  };

  const onSubmit = async () => {
    setForm(form);
    CheckPlanUser();
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <SectionMain noPadding className={classes.leftSectionSeven}>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
            }}
          >
            <div
              style={{
                width: "100%",
              }}
            >
              <div className={classes.form}>
                <input
                  onChange={({ target }) => {
                    setFormUser({ ...form, name: target.value });
                  }}
                  style={{
                    borderColor: !valid && form.name === "" ? "red" : "#dcdcdc",
                  }}
                  placeholder="* Nome da pessoa que deseja presentear"
                  type="text"
                  label="Login"
                />
                <span className={classes.descriptionSeven}>
                  Esse nome será usado no cartão digital enviado ao presenteado.
                </span>
              </div>
              <div className={classes.form}>
                <input
                  onChange={({ target }) => {
                    setFormUser({ ...form, email: target.value });
                  }}
                  style={{
                    borderColor:
                      !valid && form.email === "" ? "red" : "#dcdcdc",
                  }}
                  placeholder="* Email da pessoa que deseja presentear"
                  type="text"
                  label="Login"
                />
                <span className={classes.descriptionSeven}>
                  Certifique-se de usar um e-mail válido para que o presente
                  chegue corretamente.
                </span>
              </div>
            </div>
            <div className={classes.form}>
              <textarea
                onChange={({ target }) => {
                  setFormUser({ ...form, text: target.value });
                }}
                style={{
                  fontFamily: "Century Gothic",
                  padding: 10,
                  fontSize: 16,
                  borderColor: !valid && form.text === "" ? "red" : "#dcdcdc",
                  width: "100%",
                  resize: "none",
                }}
                placeholder="*Escreva uma mensagem para a pessoa que será presenteada"
                type="text"
                label="Login"
                rows={7}
                maxLength={250}
              />
            </div>
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <ButtonMedita
              type="button"
              style={{ textTransform: "capitalize" }}
              onClick={onSubmit}
            >
              Próxima etapa
            </ButtonMedita>
          </div>
        </div>
      </SectionMain>
      <ModalInfoPlan modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} />
      <ModalInfoLogin
        modalIsOpen={modalLoginIsOpen}
        setIsOpen={setLoginIsOpen}
        nextStep={nextStep}
      />
    </div>
  );
};

export default StepOneCard;
