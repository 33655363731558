import { IconButton, makeStyles } from "@material-ui/core";
import React from "react";
import Modal from "react-modal";
import SectionMain from "../../SectionMain";
import { Close } from "@material-ui/icons";
import ButtonMedita from "../../ButtonMedita";
import useGoToPage from "../../../hooks/useGoToPage";
import ModalLogin from "../../ModalLogin";

const useStyles = makeStyles({
  planButton: {
    width: "100% !important",
    alignSelf: "center",
    marginLeft: "auto",
    marginRight: "auto",
  },

  modal: {
    width: "90%",
    background: "#fff",
    margin: "200px auto",
    borderRadius: "13px",
    padding: "20px",
    background: "#eee",
    textAlign: "center",
  },
  modalTitle: {
    fontSize: 15,
    marginBottom: 20,
    textAlign: "center",
    width: "100%",
  },
  modalDeleteTitle: {
    fontSize: 20,
    marginBottom: 20,
    textAlign: "center",
    width: "100%",
  },
  p: {
    padding: 0,
    margin: 5,
    fontWeight: "bold",
    color: "#454545",
  },
  formMobile: {
    width: "auto",
    padding: 20,
  },
  buttonEnviar: {
    textTransform: "inherit",
    width: "247px !important",
  },
  register: {
    color: "red",
    cursor: "pointer",
    padding: "14px 20px !important",
    fontWeight: "600 !important",
    letterSpacing: "1px",
    textTransform: "uppercase",
    textDecorationLine: "none",
    margin: "10px 0 15px 5px",
    borderRadius: "30px !important",
    width: "300px !important",
    height: "50px !important",
  },

  text: {
    fontSize: "16px",
    width: "50px",
    color: "red",
    fontWeight: "bold",
  },
});

const ModalInfoLogin = ({ modalIsOpen, nextStep, setIsOpen }) => {
  const goToPage = useGoToPage();
  const [modalLogin, setModalLogin] = React.useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  const classes = useStyles();

  return (
    <SectionMain noPadding>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Cancelar Assinatura"
        className={classes.modal}
      >
        <div style={{ display: "flex" }}>
          <IconButton size="large" onClick={closeModal}>
            <Close />
          </IconButton>
        </div>
        <h2 className={classes.modalDeleteTitle}>Entre na sua conta</h2>

        <p>
          Para continuar é necessário ter uma conta na MeditaBem. Faça login ou
          crie uma conta agora mesmo.
        </p>

        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <ButtonMedita
            type="button"
            onClick={() => {
              closeModal();
              setModalLogin(true);
            }}
          >
            ENTRAR
          </ButtonMedita>
          <ButtonMedita
            style={{ marginLeft: 5 }}
            variant="outlined"
            type="button"
            onClick={nextStep}
          >
            CRIAR CONTA
          </ButtonMedita>
        </div>
      </Modal>

      <ModalLogin hiddenButton open={modalLogin} />
    </SectionMain>
  );
};

export default ModalInfoLogin;
