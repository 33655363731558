import React, { useState } from "react";
import "./style.css";
import "./styleCard.css";
import ButtonMedita from "../ButtonMedita";
import { makeStyles } from "@material-ui/core";
import IMask from "imask";
import Axios from "axios";
import api from "../../core/api";
import { useSnackbar } from "notistack";
import {
  amex,
  amex_single,
  diners,
  diners_single,
  discover,
  discover_single,
  jcb,
  jcb_single,
  maestro,
  maestro_single,
  mastercard,
  mastercard_single,
  unionpay,
  unionpay_single,
  visa,
  visa_single,
} from "./components/svgs";
import useGetPlans from "../../hooks/useGetPlans";
import StepThreeCard from "./StepThreeCard";
import SuccessCard from "./SuccessCard";

const useStyles = makeStyles({
  p: {
    fontWeight: "600",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  pSpan: {
    fontWeight: "600",
    marginBottom: 0,
    textAlign: "left",
  },
  pInfos: {
    fontWeight: "300",
    marginBottom: 0,
    textAlign: "center",
    color: "#030102",
    fontSize: "12px",
    padding: 25,
  },
  stepCard: {
    display: "flex",
    marginTop: "15px",
  },
  modalCancel: {
    width: "500px",
    background: "#fff",
    margin: "200px auto",
    borderRadius: "13px",
    padding: "20px",
    textAlign: "center",
  },
  titleNotice: {
    fontFamily: "Century Gothic",
    fontSize: 13,
    fontWeight: "normal",
    fontStyle: "normal",
    textAlign: "center",
    color: "#183B56",
  },
  bold: { fontWeight: "bold" },
  containerSeven: {
    backgroundColor: "#F3FAF7",
    borderRadius: "150% 150% 0% 0%/40% 40% 0% 0%",
    paddingBottom: "5%",
    position: "absolute",
    left: 0,
  },
  leftSectionSeven: {
    height: "100%",
    maxWidth: "75%",
    margin: "auto",
    marginTop: 50,
  },
  titleSectionSeven: {
    fontFamily: "Century Gothic",
    fontSize: "36px",
    fontWeight: "normal",
    fontStyle: "normal",
    textAlign: "center",
    color: "#3bad76",
    marginTop: 10,
  },
  descriptionSeven: {
    fontFamily: "Century Gothic",
    fontSize: "18px",
    fontWeight: "normal",
    fontStyle: "normal",
    textAlign: "left",
    color: "#5a7084",
  },
  sealView: {
    paddingLeft: 20,
    paddingRight: 20,
    backgroundColor: "#E1F3EC",
    borderRadius: 40,
    width: "fit-content",
    margin: "auto",
  },
  sealText: {
    fontFamily: "Century Gothic",
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
    textAlign: "center",
    color: "#36B37E",
  },
});

const StepTwoCard = ({ form, nextStep, prevStep }) => {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const snackbar = (message) => {
    enqueueSnackbar(`${message}`, {
      variant: "error",
      anchorOrigin: {
        horizontal: "right",
        vertical: "top",
      },
    });
  };

  const [formStep, setFormStep] = React.useState(0);
  const nameUser = localStorage.getItem("name");
  const [name, setName] = React.useState("");
  const [loadingSubscription, setLoadingSubscription] = React.useState(false);
  const [cpf, setCpf] = React.useState("");
  const [postalCode, setPostalCode] = React.useState("");
  const [addressNumber, setAddressNumber] = React.useState("");
  const [cellphone, setCellphone] = React.useState("");
  const [subsResponse, setSubsResponse] = useState(null);
  const { plans: plansApi } = useGetPlans();

  async function getIp() {
    const { data: ip } = await Axios.get("https://api.ipify.org/?format=json");
    return ip.ip;
  }

  const onHandleSubscription = async () => {
    const name = document.getElementById("name");
    const cardnumber = document.getElementById("cardnumber");
    const expirationdate = document.getElementById("expirationdate");
    const securitycode = document.getElementById("securitycode");

    if (!name.value) {
      snackbar("Nome no cartão obrigatório. Por favor preencha");
      return;
    }
    if (!cardnumber.value) {
      snackbar("Número do cartão obrigatório. Por favor preencha");
      return;
    }
    if (!expirationdate.value) {
      snackbar("Data de vencimento obrigatória. Por favor preencha");
      return;
    }
    if (!securitycode.value) {
      snackbar("CVV obrigatório. Por favor preencha");
      return;
    }
    if (!cpf) {
      snackbar("Cpf obrigatório. Por favor preencha");
      return;
    }

    const ip = await getIp();
    const dateExpirationArray = expirationdate.value.split("/");
    const er = /[^a-z0-9]/gi;
    const body = {
      id: localStorage.getItem("id"),
      externalReference: localStorage.getItem("id"),
      cpfCnpj: cpf.replace(er, ""),
      notificationDisabled: false,
      formGift: form,
      remoteIp: ip,
      creditCard: {
        holderName: name.value.trim(),
        number: cardnumber.value.replace(er, ""),
        expiryMonth: dateExpirationArray[0],
        expiryYear: dateExpirationArray[1],
        ccv: securitycode.value,
      },
      creditCardHolderInfo: {
        name: localStorage.getItem("name"),
        email: localStorage.getItem("email"),
        cpfCnpj: cpf.replace(er, ""),
        postalCode: postalCode,
        addressNumber: addressNumber,
        addressComplement: null,
        phone: cellphone,
        mobilePhone: cellphone,
      },
    };

    setLoadingSubscription(true);

    try {
      await api.post("/giftcards/subscription", body).then(async (res) => {
        setLoadingSubscription(false);
        if (res.status === 200) {
          setSubsResponse(res.data);
          setFormStep(2);
        }
      });
    } catch (e) {
      setLoadingSubscription(false);
      snackbar(
        e.response.data.message ||
          e.response.data ||
          "Ops.. Ocorreu um erro na assinatura. Verifique seus dados e tente novamente!"
      );
    }
    setLoadingSubscription(false);
  };

  const nameInput = document.getElementById("name");
  const cardnumber = document.getElementById("cardnumber");
  const expirationdate = document.getElementById("expirationdate");
  const securitycode = document.getElementById("securitycode");
  const ccicon = document.getElementById("ccicon");
  const ccsingle = document.getElementById("ccsingle");

  //Mask the Credit Card Number Input
  if (cardnumber) {
    var cardnumber_mask = new IMask(cardnumber, {
      mask: [
        {
          mask: "0000 000000 00000",
          regex: "^3[47]\\d{0,13}",
          cardtype: "american express",
        },
        {
          mask: "0000 0000 0000 0000",
          regex: "^(?:6011|65\\d{0,2}|64[4-9]\\d?)\\d{0,12}",
          cardtype: "discover",
        },
        {
          mask: "0000 000000 0000",
          regex: "^3(?:0([0-5]|9)|[689]\\d?)\\d{0,11}",
          cardtype: "diners",
        },
        {
          mask: "0000 0000 0000 0000",
          regex: "^(5[1-5]\\d{0,2}|22[2-9]\\d{0,1}|2[3-7]\\d{0,2})\\d{0,12}",
          cardtype: "mastercard",
        },
        // {
        //     mask: '0000-0000-0000-0000',
        //     regex: '^(5019|4175|4571)\\d{0,12}',
        //     cardtype: 'dankort'
        // },
        // {
        //     mask: '0000-0000-0000-0000',
        //     regex: '^63[7-9]\\d{0,13}',
        //     cardtype: 'instapayment'
        // },
        {
          mask: "0000 000000 00000",
          regex: "^(?:2131|1800)\\d{0,11}",
          cardtype: "jcb15",
        },
        {
          mask: "0000 0000 0000 0000",
          regex: "^(?:35\\d{0,2})\\d{0,12}",
          cardtype: "jcb",
        },
        {
          mask: "0000 0000 0000 0000",
          regex: "^(?:5[0678]\\d{0,2}|6304|67\\d{0,2})\\d{0,12}",
          cardtype: "maestro",
        },
        // {
        //     mask: '0000-0000-0000-0000',
        //     regex: '^220[0-4]\\d{0,12}',
        //     cardtype: 'mir'
        // },
        {
          mask: "0000 0000 0000 0000",
          regex: "^4\\d{0,15}",
          cardtype: "visa",
        },
        {
          mask: "0000 0000 0000 0000",
          regex: "^62\\d{0,14}",
          cardtype: "unionpay",
        },
        {
          mask: "0000 0000 0000 0000",
          cardtype: "Unknown",
        },
      ],
      dispatch: function (appended, dynamicMasked) {
        var number = (dynamicMasked.value + appended).replace(/\D/g, "");

        for (var i = 0; i < dynamicMasked.compiledMasks.length; i++) {
          let re = new RegExp(dynamicMasked.compiledMasks[i].regex);
          if (number.match(re) != null) {
            return dynamicMasked.compiledMasks[i];
          }
        }
      },
    });

    //Mask the Expiration Date
    var expirationdate_mask = new IMask(expirationdate, {
      mask: "00{/}00",
    });

    //Mask the security code
    var securitycode_mask = new IMask(securitycode, {
      mask: "0000",
    });

    IMask(document.getElementById("cpfcnpj"), {
      mask: [
        {
          mask: "000.000.000-00",
          maxLength: 11,
        },
        {
          mask: "00.000.000/0000-00",
        },
      ],
    });

    IMask(document.getElementById("cell"), {
      mask: [
        {
          mask: "(00) 0000-0000",
          maxLength: 11,
        },
        {
          mask: "(00) 0 0000-0000",
        },
      ],
    });

    IMask(document.getElementById("cep"), {
      mask: [
        {
          mask: "00.000-000",
        },
      ],
    });

    //define the color swap function
    const swapColor = function (basecolor) {
      document.querySelectorAll(".lightcolor").forEach(function (input) {
        input.setAttribute("class", "");
        input.setAttribute("class", "lightcolor " + basecolor);
      });
      document.querySelectorAll(".darkcolor").forEach(function (input) {
        input.setAttribute("class", "");
        input.setAttribute("class", "darkcolor " + basecolor + "dark");
      });
    };

    //pop in the appropriate card icon when detected
    cardnumber_mask.on("accept", function () {
      switch (cardnumber_mask.masked.currentMask.cardtype) {
        case "american express":
          ccicon.innerHTML = amex;
          ccsingle.innerHTML = amex_single;
          swapColor("green");
          break;
        case "visa":
          ccicon.innerHTML = visa;
          ccsingle.innerHTML = visa_single;
          swapColor("lime");
          break;
        case "diners":
          ccicon.innerHTML = diners;
          ccsingle.innerHTML = diners_single;
          swapColor("orange");
          break;
        case "discover":
          ccicon.innerHTML = discover;
          ccsingle.innerHTML = discover_single;
          swapColor("purple");
          break;
        case "jcb" || "jcb15":
          ccicon.innerHTML = jcb;
          ccsingle.innerHTML = jcb_single;
          swapColor("red");
          break;
        case "maestro":
          ccicon.innerHTML = maestro;
          ccsingle.innerHTML = maestro_single;
          swapColor("yellow");
          break;
        case "mastercard":
          ccicon.innerHTML = mastercard;
          ccsingle.innerHTML = mastercard_single;
          swapColor("lightblue");

          break;
        case "unionpay":
          ccicon.innerHTML = unionpay;
          ccsingle.innerHTML = unionpay_single;
          swapColor("cyan");
          break;
        default:
          ccicon.innerHTML = "";
          ccsingle.innerHTML = "";
          swapColor("grey");
          break;
      }
    });

    // CREDIT CARD IMAGE JS
    if (document.querySelector(".preload"))
      document.querySelector(".preload").classList.remove("preload");
    document
      .querySelector(".creditcard")
      .addEventListener("click", function () {
        if (this.classList.contains("flipped")) {
          this.classList.remove("flipped");
        } else {
          this.classList.add("flipped");
        }
      });

    //On Input Change Events
    nameInput.addEventListener("input", function () {
      if (nameInput.value.length == 0) {
        document.getElementById("svgname").innerHTML = "John Doe";
        document.getElementById("svgnameback").innerHTML = "John Doe";
      } else {
        document.getElementById("svgname").innerHTML = this.value;
        document.getElementById("svgnameback").innerHTML = this.value;
      }
    });

    cardnumber_mask.on("accept", function () {
      if (cardnumber_mask.value.length == 0) {
        document.getElementById("svgnumber").innerHTML = "0123 4567 8910 1112";
      } else {
        document.getElementById("svgnumber").innerHTML = cardnumber_mask.value;
      }
    });

    expirationdate_mask.on("accept", function () {
      if (expirationdate_mask.value.length == 0) {
        document.getElementById("svgexpire").innerHTML = "01/23";
      } else {
        document.getElementById("svgexpire").innerHTML =
          expirationdate_mask.value;
      }
    });

    securitycode_mask.on("accept", function () {
      if (securitycode_mask.value.length == 0) {
        document.getElementById("svgsecurity").innerHTML = "985";
      } else {
        document.getElementById("svgsecurity").innerHTML =
          securitycode_mask.value;
      }
    });

    //On Focus Events
    nameInput.addEventListener("focus", function () {
      document.querySelector(".creditcard").classList.remove("flipped");
    });

    cardnumber.addEventListener("focus", function () {
      document.querySelector(".creditcard").classList.remove("flipped");
    });

    expirationdate.addEventListener("focus", function () {
      document.querySelector(".creditcard").classList.remove("flipped");
    });

    securitycode.addEventListener("focus", function () {
      document.querySelector(".creditcard").classList.add("flipped");
    });
  }

  return formStep === 0 ? (
    <StepThreeCard
      form={{ ...form, nameUser, value: plansApi[1]?.value }}
      nextStep={() => setFormStep(1)}
      prevStep={prevStep}
    />
  ) : formStep === 1 ? (
    <div style={{ width: "100%" }}>
      <div
        className="form"
        style={{
          width: "700px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            width: "75%",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
            padding: 15,
            borderRadius: 15,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: 20,
          }}
        >
          <div className="container preload">
            <div className="creditcard">
              <div className="front">
                <div id="ccsingle"></div>
                <svg
                  version="1.1"
                  id="cardfront"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 750 471"
                >
                  <g id="Front">
                    <rect
                      x="0.5"
                      width="100%"
                      height="100%"
                      rx="16"
                      fill="url(#paint0_linear_93_261)"
                    />
                    <linearGradient
                      id="paint0_linear_93_261"
                      x1="0.5"
                      y1="0"
                      x2="308.989"
                      y2="209.429"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#FED4B4" />
                      <stop offset="1" stop-color="#3BB9A1" />
                    </linearGradient>
                    <text
                      transform="matrix(1 0 0 1 60.106 295.0121)"
                      id="svgnumber"
                      className="st2 st3 st4"
                    >
                      0123 4567 8910 1112
                    </text>

                    <text
                      transform="matrix(1 0 0 1 54.1064 428.1723)"
                      id="svgname"
                      className="stUp"
                      textLength={name.length > 20 ? "50%" : ""}
                    >
                      Harley Keener
                    </text>

                    <text
                      transform="matrix(1 0 0 1 54.1074 389.8793)"
                      className="st7 st5 st8"
                    >
                      Nome
                    </text>
                    <text
                      transform="matrix(1 0 0 1 479.7754 388.8793)"
                      className="st7 st5 st8"
                    >
                      Data de validade
                    </text>

                    <g>
                      <text
                        transform="matrix(1 0 0 1 479.7754 433.8095)"
                        id="svgexpire"
                        className="st2 st5 st9"
                      >
                        01/23
                      </text>
                    </g>
                    <g id="cchip">
                      <g>
                        <path
                          className="st2"
                          d="M168.1,143.6H82.9c-10.2,0-18.5-8.3-18.5-18.5V74.9c0-10.2,8.3-18.5,18.5-18.5h85.3
                        c10.2,0,18.5,8.3,18.5,18.5v50.2C186.6,135.3,178.3,143.6,168.1,143.6z"
                        />
                      </g>
                      <g>
                        <g>
                          <rect
                            x="82"
                            y="70"
                            className="st12"
                            width="1.5"
                            height="60"
                          />
                        </g>
                        <g>
                          <rect
                            x="167.4"
                            y="70"
                            className="st12"
                            width="1.5"
                            height="60"
                          />
                        </g>
                        <g>
                          <path
                            className="st12"
                            d="M125.5,130.8c-10.2,0-18.5-8.3-18.5-18.5c0-4.6,1.7-8.9,4.7-12.3c-3-3.4-4.7-7.7-4.7-12.3
                            c0-10.2,8.3-18.5,18.5-18.5s18.5,8.3,18.5,18.5c0,4.6-1.7,8.9-4.7,12.3c3,3.4,4.7,7.7,4.7,12.3
                            C143.9,122.5,135.7,130.8,125.5,130.8z M125.5,70.8c-9.3,0-16.9,7.6-16.9,16.9c0,4.4,1.7,8.6,4.8,11.8l0.5,0.5l-0.5,0.5
                            c-3.1,3.2-4.8,7.4-4.8,11.8c0,9.3,7.6,16.9,16.9,16.9s16.9-7.6,16.9-16.9c0-4.4-1.7-8.6-4.8-11.8l-0.5-0.5l0.5-0.5
                            c3.1-3.2,4.8-7.4,4.8-11.8C142.4,78.4,134.8,70.8,125.5,70.8z"
                          />
                        </g>
                        <g>
                          <rect
                            x="82.8"
                            y="82.1"
                            className="st12"
                            width="25.8"
                            height="1.5"
                          />
                        </g>
                        <g>
                          <rect
                            x="82.8"
                            y="117.9"
                            className="st12"
                            width="26.1"
                            height="1.5"
                          />
                        </g>
                        <g>
                          <rect
                            x="142.4"
                            y="82.1"
                            className="st12"
                            width="25.8"
                            height="1.5"
                          />
                        </g>
                        <g>
                          <rect
                            x="142"
                            y="117.9"
                            className="st12"
                            width="26.2"
                            height="1.5"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                  <g id="Back"></g>
                </svg>
              </div>
              <div className="back">
                <svg
                  version="1.1"
                  id="cardback"
                  xmlns="http://www.w3.org/2000/svg"
                  xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 750 471"
                  style={{ enableBackground: "new 0 0 750 471" }}
                  space="preserve"
                  overflow="auto"
                >
                  <g id="Front">
                    <line
                      className="st0"
                      x1="35.3"
                      y1="10.4"
                      x2="36.7"
                      y2="11"
                    />
                  </g>
                  <g id="Back">
                    <g id="Page-1_2_">
                      <g id="amex_2_">
                        <path
                          id="Rectangle-1_2_"
                          className="darkcolor greydark"
                          d="M40,0h670c22.1,0,40,17.9,40,40v391c0,22.1-17.9,40-40,40H40c-22.1,0-40-17.9-40-40V40
                        C0,17.9,17.9,0,40,0z"
                        />
                      </g>
                    </g>
                    <rect y="61.6" className="st2" width="750" height="78" />
                    <g>
                      <path
                        className="st3"
                        d="M701.1,249.1H48.9c-3.3,0-6-2.7-6-6v-52.5c0-3.3,2.7-6,6-6h652.1c3.3,0,6,2.7,6,6v52.5
                    C707.1,246.4,704.4,249.1,701.1,249.1z"
                      />
                      <rect
                        x="42.9"
                        y="198.6"
                        className="st4"
                        width="664.1"
                        height="10.5"
                      />
                      <rect
                        x="42.9"
                        y="224.5"
                        className="st4"
                        width="664.1"
                        height="10.5"
                      />
                      <path
                        className="st5"
                        d="M701.1,184.6H618h-8h-10v64.5h10h8h83.1c3.3,0,6-2.7,6-6v-52.5C707.1,187.3,704.4,184.6,701.1,184.6z"
                      />
                    </g>
                    <text
                      transform="matrix(1 0 0 1 621.999 227.2734)"
                      id="svgsecurity"
                      className="st6 st7"
                    >
                      985
                    </text>
                    <g className="st8">
                      <text
                        transform="matrix(1 0 0 1 518.083 280.0879)"
                        className="st9 st6 st10"
                      >
                        security code
                      </text>
                    </g>
                    <rect
                      x="58.1"
                      y="378.6"
                      className="st11"
                      width="375.5"
                      height="13.5"
                    />
                    <rect
                      x="58.1"
                      y="405.6"
                      className="st11"
                      width="421.7"
                      height="13.5"
                    />
                    <text
                      transform="matrix(1 0 0 1 59.5073 228.6099)"
                      id="svgnameback"
                      className="st12 st15"
                    >
                      Harley Keener
                    </text>
                  </g>
                </svg>
              </div>
            </div>
          </div>
          <div className={classes.p}>
            <p className={classes.pSpan}>
              Informe os dados do seu cartão de crédito
            </p>
          </div>
          <div className="form-container">
            <div className="field-container">
              <label for="name">
                Nome no cartão<label style={{ color: "red" }}>*</label>
              </label>
              <input
                id="name"
                type="text"
                onChange={({ target }) => {
                  setName(target.value);
                }}
              />
            </div>
            <div className="field-container">
              <label for="cardnumber">
                Número do Cartão<label style={{ color: "red" }}>*</label>
              </label>

              <input
                id="cardnumber"
                type="text"
                pattern="[0-9]*"
                inputmode="numeric"
              />
              <svg
                id="ccicon"
                className="ccicon"
                width="750"
                height="471"
                viewBox="0 0 750 471"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xlink="http://www.w3.org/1999/xlink"
              ></svg>
            </div>
            <div className="field-container">
              <label for="expirationdate">
                Data de Validade<label style={{ color: "red" }}>*</label>
              </label>
              <input
                id="expirationdate"
                type="text"
                pattern="[0-9]*"
                inputmode="numeric"
              />
            </div>
            <div className="field-container">
              <label for="securitycode">
                CVV<label style={{ color: "red" }}>*</label>
              </label>
              <input
                id="securitycode"
                type="text"
                pattern="[0-9]*"
                inputmode="numeric"
              />
            </div>
            <div className="field-container">
              <label for="cpf">
                CPF/CNPJ<label style={{ color: "red" }}>*</label>
              </label>
              <input
                id="cpfcnpj"
                maxlength="20"
                type="text"
                onChange={(text) => setCpf(text.target.value)}
              />
            </div>
            <div className="field-container">
              <label for="cell">
                Celular<label style={{ color: "red" }}>*</label>
              </label>
              <input
                id="cell"
                maxlength="20"
                type="text"
                onChange={(text) => setCellphone(text.target.value)}
              />
            </div>
            <div className="field-container">
              <label for="cep">CEP</label>
              <input
                id="cep"
                type="text"
                pattern="[0-9]*"
                inputmode="numeric"
                onChange={(text) => setPostalCode(text.target.value)}
              />
            </div>
            <div className="field-container">
              <label for="securitycode">Nº do endereço</label>
              <input
                id="securitycode"
                type="text"
                pattern="[0-9]*"
                inputmode="numeric"
                onChange={(text) => setAddressNumber(text.target.value)}
              />
            </div>
          </div>
          <div style={{ marginTop: 10, width: "100%", marginBottom: 15 }}>
            <label>Plano & Preço selecionado</label>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: 10,
                backgroundColor: "#f4f4f4",
                height: 50,
              }}
            >
              <span style={{ width: "50%" }}>{form?.description}</span>
              <span style={{ width: "50%" }}>
                R$ {plansApi[1]?.value || "-"}
              </span>
            </div>
          </div>
          <div className={classes.p}>
            <p className={classes.pInfos}>
              Ao clicar no botão “Confirmar Pagamento” abaixo, você concorda com
              nosso Termo de Uso e Privacidade, confirma ter mais de 18 anos e
              aceita que a MeditaBem renove automaticamente sua assinatura e
              cobre o preço da assinatura acima da sua forma de pagamento até
              você cancelar. Você pode cancelar quando quiser para evitar
              cobranças futuras. Para cancelar, acesse seu perfil no site e
              clique em “Cancelar assinatura”.
            </p>
          </div>
          <ButtonMedita
            disabled={loadingSubscription}
            loading={loadingSubscription}
            type="button"
            onClick={onHandleSubscription}
            size="large"
            style={{ marginTop: "10px" }}
          >
            Confirmar Pagamento
          </ButtonMedita>
          <ButtonMedita
            // disabled={formikProps.isSubmitting}
            variant="outlined"
            type="button"
            onClick={prevStep}
            size="large"
            style={{ marginTop: "10px" }}
          >
            Cancelar
          </ButtonMedita>
        </div>
      </div>
    </div>
  ) : (
    <SuccessCard subsResponse={subsResponse} nextStep={nextStep} />
  );
};

export default StepTwoCard;
