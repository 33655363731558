import React, { useEffect, useState } from "react";
import api from "../../../core/api";
import useGoToPage from "../../../hooks/useGoToPage";
import SectionMain from "../../../components/SectionMain";
import ButtonMedita from "../../../components/ButtonMedita";
import {
  CircularProgress,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import Title from "../../../components/Title";
import {
  amex_brand,
  diners_brand,
  discover_brand,
  jcb_brand,
  maestro_brand,
  mastercard_brand,
  unionpay_brand,
  visa_brand,
} from "../../../components/Stepper/components/svgs";
import { format, parseISO } from "date-fns";
import Modal from "react-modal";
import { Close, Visibility } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import ModalFormResult from "../../../components/ModalFormResult";
import InputText from "../../../components/InputText";
import { useUpdateUser } from "../../../hooks/useUpdateUser";
import CardData from "./cardData";
import { StatusPayment } from "../types";
import GiftCardSection from "./GiftCardSection";

const useStyles = makeStyles({
  container: {
    width: "100%",
    margin: "30px",
    display: "flex",
  },
  title: { justifyContent: "center", margin: "20px 0 40px" },
  desc: { padding: "40px" },
  form: { marginTop: "20px", marginBottom: "20px" },
  line: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "space-between",
  },
  submit: {
    background: "#316570 !important",
    width: "226px !important",
    minWidth: "226px !important",
    fontSize: "18px !important",
  },
  facebookButton: {
    height: "56px",
    borderRadius: "20px",
    border: "none",
    color: "#282828 !important",
    textTransform: "none",
    width: "600px !important",
    minWidth: "360px !important",
    boxShadow: "none",
  },
  modal: {
    padding: "20px",
    width: "1200px",
  },
  modalCancel: {
    width: "500px",
    background: "#fff",
    margin: "200px auto",
    borderRadius: "13px",
    padding: "20px",
    textAlign: "center",
  },
  link: {
    textDecoration: "underline",
    color: "black",
    fontWeight: "bold",
  },
  linksContainer: {
    backgroundColor: "#EDEDED",
    display: "flex",
    marginTop: 10,
    justifyContent: "space-around",
  },
  buttonEnviar: {
    textTransform: "inherit",
    width: "247px !important",
    marginTop: 5,
  },
  mTop: {
    marginTop: 5,
  },
});

const AccountPageDesktop = () => {
  const goToPage = useGoToPage();
  const [loading, setLoading] = useState(false);
  const [dataUser, setDataUser] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalRenewIsOpen, setRenewIsOpen] = useState(false);
  const [modalSuccess, setIsOpenSuccess] = useState(false);
  const [modalUpdatePayment, setUpdatePayment] = useState(null);
  const [modalHeader, setModalHeader] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [modalUpdateIsOpen, setUpdateIsOpen] = useState(false);
  const [modalUpdatePassIsOpen, setUpdatePassIsOpen] = useState(false);
  const [name, setName] = useState("");
  const [nickName, setNickName] = useState(" ");
  const [password, setPassword] = useState("**");
  const [viewPass, setViewPass] = useState(false);
  const [loadingB, setLoadingB] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [viewConfirmPass, setViewConfirmPass] = useState(false);
  const [subscription, setSubs] = useState(null);
  const [statusPayments, setStatusPay] = useState(null);
  const [assasSubId, setAssasSubId] = useState(null);
  const [payments, setPayments] = useState(null);
  const [expiration, setExpiration] = useState(null);
  const [statusSubsUser, setStatusSubsUser] = useState(null);
  const [loadingSubscription, setLoadingSubscription] = useState(false);
  const [tablePayment, setTablePayment] = useState([
    "Data Vencimento",
    "Descrição",
    "Valor",
    "Status",
    "Data de emissão",
  ]);

  const id = localStorage.getItem("id");
  const plano = localStorage.getItem("plano");

  const { enqueueSnackbar } = useSnackbar();
  const snackbar = (message) => {
    enqueueSnackbar(`${message}`, {
      variant: "error",
      anchorOrigin: {
        horizontal: "right",
        vertical: "top",
      },
    });
  };

  const openModalResult = (header, body) => {
    setIsOpenSuccess(true);
    setModalHeader(header);
    setModalBody(body);
  };

  function closeModalSuccess() {
    setIsOpenSuccess(false);
    setIsOpen(false);
    setUpdateIsOpen(false);
    window.location.reload();
  }

  const cancelSubscription = async () => {
    const id = localStorage.getItem("id");
    setLoadingSubscription(true);
    await api
      .delete(`/subscriptions/${id}`)
      .then(async (res) => {
        setLoadingSubscription(false);
        openModalResult(`Sucesso!`, "Sua assinatura foi cancelada.");
      })
      .catch((e) => {
        console.log(e);
        setIsOpen(false);
        setLoadingSubscription(false);
        snackbar("Ops.. algo de errado aconteceu. Tente novamente mais tarde!");
      });
  };

  const updateUser = async (passwordUp) => {
    let payload = { name, nickName };
    if (passwordUp) {
      payload = { password };
      if (password !== confirmPassword) {
        snackbar("Senhas não coincidem");
        return;
      }
    }

    setLoading(true);
    try {
      await api.put(`users/${id}`, payload);
      openModalResult(`Sucesso!`, "Seus dados foram alterados.");
    } catch (err) {
      setUpdateIsOpen(false);
      snackbar("Ops.. algo de errado aconteceu. Tente novamente mais tarde!");
    } finally {
      setLoading(false);
    }
  };

  const GetUser = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`users/${id}`);
      const { data: result } = await api.post(`subscriptions/asaas/${id}`);

      let r = {};
      if (result) {
        r = result?.subscriptions?.data.filter(
          (pay) => pay.status === "ACTIVE"
        )[0];

        setStatusPay(result?.payStatus);
        setPayments({
          payments: result?.payments?.data
            ?.sort(function (a, b) {
              return new Date(a.originalDueDate) - new Date(b.originalDueDate);
            })
            .map((pay) => {
              return {
                id: pay.id,
                originalDueDate: format(
                  parseISO(pay.originalDueDate),
                  "dd/MM/yyyy"
                ),
                estimatedCreditDate: pay?.estimatedCreditDate
                  ? format(parseISO(pay?.estimatedCreditDate), "dd/MM/yyyy")
                  : null,
                nextDate: pay.estimatedCreditDate,
                description: pay.description,
                status: pay.status,
                value: pay.value,
                dateCreated: format(parseISO(pay.dateCreated), "dd/MM/yyyy"),
              };
            }),
          subscription: r || result?.subscription,
        });
      }

      if (data) {
        let dados = {
          period:
            data.premium === "M"
              ? "Mensal"
              : data.premium === "Y"
              ? "Anual"
              : data.premium === "S"
              ? "Especial"
              : data.premium === "G"
              ? "GiftCard"
              : data.premium === "N"
              ? "14 dias Free"
              : "FREE",
          plan:
            data.premium === "M"
              ? "Medita.Bem+"
              : data.premium === "Y"
              ? "Medita.Sempre"
              : data.premium === "S"
              ? "Especial"
              : data.premium === "G"
              ? "GiftCard"
              : "FREE",
        };

        const asaas = data.dataAsaas || result?.subscriptions?.data[0];

        if (asaas.status) {
          const dados1 = {
            ...dados,
            dateCreated: format(parseISO(asaas.dateCreated), "dd/MM/yyyy"),
            nextDueDate: format(parseISO(asaas.nextDueDate), "dd/MM/yyyy"),
            totally: asaas.value,
            cardNumber: asaas.creditCard?.creditCardNumber,
            cardBrand: asaas.creditCard?.creditCardBrand,
            billingType: asaas.billingType,
            statusAss: asaas.status,
          };
          dados = dados1;
        }

        setDataUser({ ...data, dataAsaas: dados });
        setName(data.name);
        setNickName(data.nickName);

        const ccicon = document.getElementById("ccicon");

        if (data.dataAsaas.creditCard?.creditCardBrand && ccicon) {
          switch (data.dataAsaas.creditCard?.creditCardBrand?.toLowerCase()) {
            case "american express":
              ccicon.innerHTML = amex_brand;
              break;
            case "visa":
              ccicon.innerHTML = visa_brand;
              break;
            case "diners":
              ccicon.innerHTML = diners_brand;
              break;
            case "discover":
              ccicon.innerHTML = discover_brand;
              break;
            case "jcb" || "jcb15":
              ccicon.innerHTML = jcb_brand;
              break;
            case "maestro":
              ccicon.innerHTML = maestro_brand;
              break;
            case "mastercard":
              ccicon.innerHTML = mastercard_brand;
              break;
            case "unionpay":
              ccicon.innerHTML = unionpay_brand;
              break;
            default:
              ccicon.innerHTML = "";
              break;
          }
        }
      }
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    GetUser();
  }, []);

  useEffect(() => {
    const tokenVerify = localStorage.getItem("token");
    if (!tokenVerify) window.location.href = "/";
  }, []);

  const classes = useStyles();

  const getSubscrition = async () => {
    const id = localStorage.getItem("id");
    if (id) {
      try {
        await api.get(`/subscriptions/users/${id}`).then(async ({ data }) => {
          setSubs(data);
        });
      } catch (e) {
        // snackbar("Ops.. algo de errado aconteceu. Tente novamente mais tarde!");
      }
    }
  };

  useEffect(() => {
    getSubscrition();
  }, []);

  const dataPay = dataUser?.dataAsaas?.nextDueDate?.split("/");

  const UpdateUserStorage = async () => {
    setLoadingB(true);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const res = await useUpdateUser();
    let sub_id;
    try {
      const result = JSON.parse(res.subscription_assas_id);
      setStatusSubsUser(result[result.length - 1]);
      sub_id = result.filter((r) => r.status === "ACTIVE")[0].id;
    } catch (error) {
      sub_id = res.subscription_assas_id;
    }
    setAssasSubId(sub_id);
    setLoadingB(false);
  };

  useEffect(() => {
    UpdateUserStorage();
  }, []);

  useEffect(() => {
    if (subscription?.subscriptions?.endDate) {
      const today = subscription?.subscriptions?.endDate;
      const date = `${today.slice(8, 10)}/${today.slice(5, 7)}/${today.slice(
        0,
        4
      )}`;

      setExpiration(date);
    }
  }, [subscription]);

  useEffect(() => {
    if (payments?.payments?.filter((p) => p.status === "OVERDUE").length > 0) {
      setTablePayment([...tablePayment, "Ações"]);
    }
  }, [payments]);

  return (
    <div>
      <SectionMain className={classes.modal} position="center">
        <SectionMain className={classes.title}>
          <Title size="xbig" fontWeight="700" color="#424242" align="left">
            MINHA CONTA
          </Title>
          <Divider orientation="horizontal" color="red" width="100%" />

          {loading && !dataUser ? (
            <CircularProgress />
          ) : modalUpdatePayment != null || modalRenewIsOpen ? (
            <CardData
              payment={modalUpdatePayment}
              renewSubs={modalRenewIsOpen}
              setRenewIsOpen={setRenewIsOpen}
              actualPlan={dataUser?.dataAsaas?.period}
              setUpdatePayment={setUpdatePayment}
              OnSuccess={() => {
                console.log("opee");
                setLoadingSubscription(false);
                openModalResult(`Sucesso!`, "Seu pagamento foi atualizado.");
              }}
              OnSuccessRenew={() => {
                setLoadingSubscription(false);
                openModalResult(`Sucesso!`, "Sua assinatura foi renovada.");
              }}
            />
          ) : (
            <div style={{ width: "100%" }}>
              <div className={classes.container}>
                <div className={classes.line}>
                  <Title
                    size="large"
                    fontWeight="700"
                    color="#424242"
                    align="left"
                  >
                    Nome: {dataUser?.name || ""}
                  </Title>
                  <div className={classes.form}>
                    <Title
                      size="large"
                      fontWeight="400"
                      color="#424242"
                      align="left"
                      marginTop="10px"
                    >
                      Apelido: {dataUser?.nickName || ""}
                    </Title>
                  </div>
                  <Title
                    size="large"
                    fontWeight="400"
                    color="#424242"
                    align="left"
                    marginTop="10px"
                  >
                    Email: {dataUser?.email || ""}
                  </Title>
                </div>
                <div className={classes.line}>
                  <ButtonMedita
                    type="button"
                    onClick={() => {
                      setUpdateIsOpen(true);
                    }}
                    size="medium"
                    style={{ marginTop: "10px" }}
                  >
                    Alterar Dados
                  </ButtonMedita>
                  <ButtonMedita
                    type="button"
                    onClick={() => {
                      setUpdatePassIsOpen(true);
                    }}
                    size="medium"
                    style={{ marginTop: "10px" }}
                  >
                    Alterar Senha
                  </ButtonMedita>
                </div>
              </div>
              <div className={classes.container}>
                <div style={{ width: "100%" }}>
                  <Title
                    size="large"
                    fontWeight="700"
                    color="#424242"
                    align="left"
                  >
                    ASSINATURA
                  </Title>
                  <Divider orientation="horizontal" color="red" width="100%" />
                </div>
              </div>

              <div className={classes.container}>
                <div className={classes.line}>
                  <Title
                    size="large"
                    fontWeight="400"
                    color="#424242"
                    align="left"
                    marginTop="10px"
                  >
                    Plano: {dataUser?.dataAsaas?.plan}
                  </Title>

                  <div>
                    {dataUser?.dataAsaas?.plan !== "FREE" &&
                      dataUser?.dataAsaas &&
                      subscription?.transaction && (
                        <div className={classes.form}>
                          <Title
                            size="large"
                            fontWeight="400"
                            color="#424242"
                            align="left"
                            marginTop="10px"
                          >
                            Local de contratação:{" "}
                            {subscription.transaction?.platform === "site"
                              ? "Site Medita"
                              : subscription.transaction?.platform === "android"
                              ? "Play Store"
                              : subscription.transaction?.platform === "ios"
                              ? "Apple Store"
                              : "Indefinido"}
                          </Title>
                        </div>
                      )}
                    <div className={classes.form}>
                      <Title
                        size="large"
                        fontWeight="400"
                        color="#424242"
                        align="left"
                        marginTop="10px"
                      >
                        Período:{" "}
                        {plano === "G"
                          ? `Válido até ${expiration}`
                          : dataUser?.dataAsaas?.period}
                      </Title>
                    </div>
                    <div>
                      {plano === "N" && (
                        <div className={classes.form}>
                          <Title
                            size="large"
                            fontWeight="400"
                            color="#424242"
                            align="justify"
                            marginTop="20px"
                          >
                            Você está no período de experiência de{" "}
                            <b>14 dias Free</b> com acesso ilimitado para todo o
                            conteúdo do <b>MeditaBem</b> . Após esse período,
                            para continuar com acesso ilimitado, será necessário
                            a escolha de uma dos nossos planos ou continuar com
                            o <b>MeditaBem</b> com acesso parcial ao conteúdo.
                          </Title>
                        </div>
                      )}
                      {statusSubsUser?.status === "PENDING" ? (
                        plano === "M" || plano === "Y" ? (
                          <div className={classes.form}>
                            <Title
                              size="large"
                              fontWeight="400"
                              color="#424242"
                              align="justify"
                              marginTop="20px"
                            >
                              Status:{" "}
                              <b>
                                Você ja contratou a troca do seu plano atual
                              </b>
                              , mas ele ainda será mantido até o vencimento, em{" "}
                              <b>{expiration || null}</b>. Mas não se preocupe,
                              a troca do seu novo plano já está registrada e
                              será feita após esta data.{`\n`} Lembre-se que
                              durante esse período nenhuma outra ação pode ser
                              realizada, para que a transição seja concluída
                              totalmente.
                            </Title>
                          </div>
                        ) : (
                          <div className={classes.form}>
                            <Title
                              size="large"
                              fontWeight="400"
                              color="#424242"
                              align="justify"
                              marginTop="20px"
                            >
                              Status: <b>Sua assinatura está em transição.</b>{" "}
                              Aguarde a conclusão do processo de troca de plano
                              para outras alterações.
                            </Title>
                          </div>
                        )
                      ) : (
                        subscription?.subscriptions?.cancelledAt !== null &&
                        new Date(subscription?.subscriptions?.endDate) >
                          new Date() && (
                          <div className={classes.form}>
                            <Title
                              size="large"
                              fontWeight="400"
                              color="#424242"
                              align="justify"
                              marginTop="20px"
                            >
                              Status: Sua assinatura foi cancelada. Você poderá
                              continuar acessando todos os conteúdos do seu
                              aplicativo MeditaBem até o dia{" "}
                              <b>{expiration || null}</b>
                            </Title>
                          </div>
                        )
                      )}
                      {statusPayments === "PENDING" &&
                        dataUser?.dataAsaas?.plan === "FREE" &&
                        dataPay &&
                        new Date() <
                          new Date(
                            `${dataPay[2]}/${dataPay[1]}/${dataPay[0]}`
                          ) && (
                          <div className={classes.form}>
                            <Title
                              size="large"
                              fontWeight="400"
                              color="#424242"
                              align="justify"
                              marginTop="20px"
                            >
                              Status:{" "}
                              <b>Sua assinatura está aguardando pagamento.</b>
                            </Title>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
                {statusSubsUser?.status === "PENDING" ||
                subscription?.transaction?.platform === "ios" ||
                subscription?.transaction?.platform === "android" ||
                plano === "G" ||
                plano === "S" ? null : subscription?.transaction?.platform ===
                    "site" &&
                  statusSubsUser?.status === "DELETED" &&
                  (plano === "M" || plano === "Y") &&
                  assasSubId ? (
                  <div className={classes.line}>
                    <ButtonMedita
                      loading={loadingB}
                      type="button"
                      onClick={() => {
                        if (assasSubId) {
                          return setRenewIsOpen(true);
                        }
                        goToPage("/medita-bem-planos");
                      }}
                      size="medium"
                      style={{ marginTop: "10px" }}
                    >
                      Renovar Plano
                    </ButtonMedita>
                  </div>
                ) : (
                  <div className={classes.line}>
                    <ButtonMedita
                      loading={loadingB}
                      type="button"
                      onClick={() => {
                        goToPage("/medita-bem-planos");
                      }}
                      size="medium"
                      style={{ marginTop: "10px" }}
                    >
                      {dataUser?.dataAsaas?.plan === "FREE"
                        ? "Contratar Plano"
                        : "Alterar Plano"}
                    </ButtonMedita>
                  </div>
                )}
              </div>

              {subscription?.transaction?.platform === "site" &&
                dataUser?.dataAsaas?.statusAss === "ACTIVE" &&
                assasSubId && (
                  <div className={classes.container}>
                    <div style={{ width: "100%" }}>
                      <Title
                        size="large"
                        fontWeight="700"
                        color="#424242"
                        align="left"
                      >
                        FORMA DE PAGAMENTO
                      </Title>
                      <Divider
                        orientation="horizontal"
                        color="red"
                        width="100%"
                      />
                    </div>
                  </div>
                )}
              {subscription?.transaction?.platform === "site" &&
              dataUser?.dataAsaas?.statusAss === "ACTIVE" &&
              assasSubId ? (
                subscription?.transaction?.platform === "site" ? (
                  <div>
                    <div className={classes.container}>
                      <div className={classes.line}>
                        <div className="container preload">
                          <div className="creditcard">
                            <div className="front">
                              <div id="ccsingle"></div>
                              <svg
                                version="1.1"
                                id="cardfront"
                                xmlns="http://www.w3.org/2000/svg"
                                x="0px"
                                y="0px"
                                viewBox="0 0 750 471"
                              >
                                <g id="Front">
                                  <rect
                                    x="0.5"
                                    width="100%"
                                    height="100%"
                                    rx="16"
                                    fill="url(#paint0_linear_93_261)"
                                  />
                                  <linearGradient
                                    id="paint0_linear_93_261"
                                    x1="0.5"
                                    y1="0"
                                    x2="308.989"
                                    y2="209.429"
                                    gradientUnits="userSpaceOnUse"
                                  >
                                    <stop stop-color="#FED4B4" />
                                    <stop offset="1" stop-color="#3BB9A1" />
                                  </linearGradient>
                                  <text
                                    transform="matrix(1 0 0 1 60.106 295.0121)"
                                    id="svgnumber"
                                    className="st2 st3 st4"
                                  >
                                    **** **** ****{" "}
                                    {dataUser?.dataAsaas?.cardNumber}
                                  </text>
                                  <text
                                    transform="matrix(1 0 0 1 54.1064 428.1723)"
                                    id="svgname"
                                    className="st2 st5 st6"
                                  >
                                    {dataUser?.name}
                                  </text>
                                  <text
                                    transform="matrix(1 0 0 1 54.1074 389.8793)"
                                    className="st7 st5 st8"
                                  >
                                    Nome
                                  </text>
                                  <text
                                    transform="matrix(1 0 0 1 479.7754 388.8793)"
                                    className="st7 st5 st8"
                                  >
                                    Data de validade
                                  </text>

                                  <g>
                                    <text
                                      transform="matrix(1 0 0 1 479.7754 433.8095)"
                                      id="svgexpire"
                                      className="st2 st5 st9"
                                    >
                                      **/**
                                    </text>
                                  </g>
                                  <g id="cchip">
                                    <g>
                                      <path
                                        className="st2"
                                        d="M168.1,143.6H82.9c-10.2,0-18.5-8.3-18.5-18.5V74.9c0-10.2,8.3-18.5,18.5-18.5h85.3
                          c10.2,0,18.5,8.3,18.5,18.5v50.2C186.6,135.3,178.3,143.6,168.1,143.6z"
                                      />
                                    </g>
                                    <g>
                                      <g>
                                        <rect
                                          x="82"
                                          y="70"
                                          className="st12"
                                          width="1.5"
                                          height="60"
                                        />
                                      </g>
                                      <g>
                                        <rect
                                          x="167.4"
                                          y="70"
                                          className="st12"
                                          width="1.5"
                                          height="60"
                                        />
                                      </g>
                                      <g>
                                        <path
                                          className="st12"
                                          d="M125.5,130.8c-10.2,0-18.5-8.3-18.5-18.5c0-4.6,1.7-8.9,4.7-12.3c-3-3.4-4.7-7.7-4.7-12.3
                              c0-10.2,8.3-18.5,18.5-18.5s18.5,8.3,18.5,18.5c0,4.6-1.7,8.9-4.7,12.3c3,3.4,4.7,7.7,4.7,12.3
                              C143.9,122.5,135.7,130.8,125.5,130.8z M125.5,70.8c-9.3,0-16.9,7.6-16.9,16.9c0,4.4,1.7,8.6,4.8,11.8l0.5,0.5l-0.5,0.5
                              c-3.1,3.2-4.8,7.4-4.8,11.8c0,9.3,7.6,16.9,16.9,16.9s16.9-7.6,16.9-16.9c0-4.4-1.7-8.6-4.8-11.8l-0.5-0.5l0.5-0.5
                              c3.1-3.2,4.8-7.4,4.8-11.8C142.4,78.4,134.8,70.8,125.5,70.8z"
                                        />
                                      </g>
                                      <g>
                                        <rect
                                          x="82.8"
                                          y="82.1"
                                          className="st12"
                                          width="25.8"
                                          height="1.5"
                                        />
                                      </g>
                                      <g>
                                        <rect
                                          x="82.8"
                                          y="117.9"
                                          className="st12"
                                          width="26.1"
                                          height="1.5"
                                        />
                                      </g>
                                      <g>
                                        <rect
                                          x="142.4"
                                          y="82.1"
                                          className="st12"
                                          width="25.8"
                                          height="1.5"
                                        />
                                      </g>
                                      <g>
                                        <rect
                                          x="142"
                                          y="117.9"
                                          className="st12"
                                          width="26.2"
                                          height="1.5"
                                        />
                                      </g>
                                    </g>
                                  </g>
                                </g>
                                <g id="Back"></g>
                              </svg>
                            </div>
                            <div className="back">
                              <svg
                                version="1.1"
                                id="cardback"
                                xmlns="http://www.w3.org/2000/svg"
                                xlink="http://www.w3.org/1999/xlink"
                                x="0px"
                                y="0px"
                                viewBox="0 0 750 471"
                                style={{ enableBackground: "new 0 0 750 471" }}
                                space="preserve"
                              >
                                <g id="Front">
                                  <line
                                    className="st0"
                                    x1="35.3"
                                    y1="10.4"
                                    x2="36.7"
                                    y2="11"
                                  />
                                </g>
                                <g id="Back">
                                  <g id="Page-1_2_">
                                    <g id="amex_2_">
                                      <path
                                        id="Rectangle-1_2_"
                                        className="darkcolor greydark"
                                        d="M40,0h670c22.1,0,40,17.9,40,40v391c0,22.1-17.9,40-40,40H40c-22.1,0-40-17.9-40-40V40
                          C0,17.9,17.9,0,40,0z"
                                      />
                                    </g>
                                  </g>
                                  <rect
                                    y="61.6"
                                    className="st2"
                                    width="750"
                                    height="78"
                                  />
                                  <g>
                                    <path
                                      className="st3"
                                      d="M701.1,249.1H48.9c-3.3,0-6-2.7-6-6v-52.5c0-3.3,2.7-6,6-6h652.1c3.3,0,6,2.7,6,6v52.5
                      C707.1,246.4,704.4,249.1,701.1,249.1z"
                                    />
                                    <rect
                                      x="42.9"
                                      y="198.6"
                                      className="st4"
                                      width="664.1"
                                      height="10.5"
                                    />
                                    <rect
                                      x="42.9"
                                      y="224.5"
                                      className="st4"
                                      width="664.1"
                                      height="10.5"
                                    />
                                    <path
                                      className="st5"
                                      d="M701.1,184.6H618h-8h-10v64.5h10h8h83.1c3.3,0,6-2.7,6-6v-52.5C707.1,187.3,704.4,184.6,701.1,184.6z"
                                    />
                                  </g>
                                  <text
                                    transform="matrix(1 0 0 1 621.999 227.2734)"
                                    id="svgsecurity"
                                    className="st6 st7"
                                  >
                                    985
                                  </text>
                                  <g className="st8">
                                    <text
                                      transform="matrix(1 0 0 1 518.083 280.0879)"
                                      className="st9 st6 st10"
                                    >
                                      security code
                                    </text>
                                  </g>
                                  <rect
                                    x="58.1"
                                    y="378.6"
                                    className="st11"
                                    width="375.5"
                                    height="13.5"
                                  />
                                  <rect
                                    x="58.1"
                                    y="405.6"
                                    className="st11"
                                    width="421.7"
                                    height="13.5"
                                  />
                                  <text
                                    transform="matrix(1 0 0 1 59.5073 228.6099)"
                                    id="svgnameback"
                                    className="st12 st13"
                                  >
                                    Harley Keener
                                  </text>
                                </g>
                              </svg>
                            </div>
                          </div>
                        </div>

                        <div className={classes.form}>
                          {dataUser?.dataAsaas?.statusAss === "ACTIVE" &&
                            payments?.payments?.length > 0 && (
                              <>
                                <Title
                                  size="large"
                                  fontWeight="600"
                                  color="#424242"
                                  align="left"
                                  marginTop="10px"
                                >
                                  HISTÓRICO DE COBRANÇAS:{" "}
                                </Title>
                                <TableContainer>
                                  <Table
                                    aria-label="custom pagination table"
                                    className="tabela"
                                  >
                                    <TableHead>
                                      <TableRow>
                                        {tablePayment.map((p) => (
                                          <TableCell align="left">
                                            {p}
                                          </TableCell>
                                        ))}
                                      </TableRow>
                                    </TableHead>

                                    <TableBody>
                                      {payments?.payments?.map((p) => {
                                        let price = 0;
                                        let format = p.value
                                          ?.toString()
                                          ?.replace(".", ",")
                                          ?.split(",");

                                        if (p.value && format.length > 1) {
                                          if (
                                            p.value
                                              ?.toString()
                                              ?.replace(".", ",")
                                              ?.split(",")[1].length < 2
                                          ) {
                                            price = `${p.value
                                              ?.toString()
                                              .replace(".", ",")}0`;
                                          } else {
                                            price = p.value
                                              ?.toString()
                                              .replace(".", ",");
                                          }
                                        } else {
                                          price = `${p.value?.toString()},00`;
                                        }

                                        return (
                                          <TableRow key={p.id}>
                                            <TableCell
                                              align="left"
                                              size="small"
                                            >
                                              {p.originalDueDate}
                                            </TableCell>
                                            <TableCell
                                              align="left"
                                              size="small"
                                            >
                                              {p.description}
                                            </TableCell>
                                            <TableCell
                                              align="left"
                                              size="small"
                                            >
                                              R$ {price}
                                            </TableCell>
                                            <TableCell
                                              align="left"
                                              size="small"
                                            >
                                              {StatusPayment[p.status] ||
                                                p.status}
                                            </TableCell>
                                            <TableCell
                                              align="left"
                                              size="small"
                                            >
                                              {p.dateCreated}
                                            </TableCell>
                                            {tablePayment.length > 5 &&
                                              (p.status === "OVERDUE" ? (
                                                <TableCell
                                                  align="left"
                                                  size="small"
                                                >
                                                  <a
                                                    style={{
                                                      color: "red",
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                      setUpdatePayment(p);
                                                      console.log(
                                                        "tablePayment",
                                                        p
                                                      );
                                                    }}
                                                  >
                                                    Atualizar Pagamento
                                                  </a>
                                                </TableCell>
                                              ) : (
                                                <TableCell
                                                  align="left"
                                                  size="small"
                                                >
                                                  -
                                                </TableCell>
                                              ))}
                                          </TableRow>
                                        );
                                      })}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </>
                            )}
                        </div>
                      </div>
                    </div>
                    {statusSubsUser?.status === "ACTIVE" && (
                      <div className={classes.container}>
                        <ButtonMedita
                          // disabled={formikProps.isSubmitting}
                          type="button"
                          onClick={() => setIsOpen(true)}
                          size="medium"
                          style={{ marginTop: "10px" }}
                        >
                          Cancelar Assinatura
                        </ButtonMedita>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className={classes.container}>
                    <div className={classes.line}>
                      <Title
                        size="large"
                        fontWeight="700"
                        color="#424242"
                        align="left"
                        marginTop="10px"
                      >
                        O PAGAMENTO DE SUA ASSINATURA FOI FEITO NAS LOJAS DE
                        APLICATIVOS, PARA FAZER ALTERAÇÕES VOCÊ DEVE ENTRAR NAS
                        CONFIGURAÇÕES DE ASSINATURA EM SEU APARELHO.
                      </Title>
                    </div>
                  </div>
                )
              ) : null}

              {(subscription?.transaction?.platform === "ios" ||
                subscription?.transaction?.platform === "android") && (
                <>
                  <div className={classes.container}>
                    <div style={{ width: "100%" }}>
                      <Title
                        size="large"
                        fontWeight="700"
                        color="#424242"
                        align="left"
                      >
                        FORMA DE PAGAMENTO
                      </Title>
                      <Divider
                        orientation="horizontal"
                        color="red"
                        width="100%"
                      />
                    </div>
                  </div>
                  <div className={classes.container}>
                    <div className={classes.line}>
                      <Title
                        size="large"
                        fontWeight="700"
                        color="#424242"
                        align="left"
                        marginTop="10px"
                      >
                        O PAGAMENTO DE SUA ASSINATURA FOI FEITO NAS LOJAS DE
                        APLICATIVOS, PARA FAZER ALTERAÇÕES VOCÊ DEVE ENTRAR NAS
                        CONFIGURAÇÕES DE ASSINATURA EM SEU APARELHO.
                      </Title>
                    </div>
                  </div>
                </>
              )}
              <GiftCardSection />
            </div>
          )}
        </SectionMain>
      </SectionMain>

      <SectionMain>
        <div
          style={{
            display: "flex",
            width: 1200,
            marginLeft: "auto",
            marginRight: "auto",
            justifyContent: "right",
            paddingTop: 0,
          }}
        >
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => setIsOpen(false)}
            contentLabel="Cancelar Assinatura"
            className={classes.modalCancel}
          >
            <div style={{ display: "flex" }}>
              <IconButton size="large" onClick={() => setIsOpen(false)}>
                <Close />
              </IconButton>
            </div>
            <h2 className={classes.modalDeleteTitle}>
              Deseja cancelar a assinatura?
            </h2>

            <p style={{ textAlign: "left" }}>
              Confirmando o cancelamento agora, você tem acesso total ao seu app{" "}
              <b>MeditaBem</b> até{" "}
              <b>{dataUser?.dataAsaas?.nextDueDate || null}</b>.
              <p className={classes.mTop} />
              Depois, você desfrutará apenas dos áudios abertos na versão free e
              seus conteúdos premium, salvos e de playlists estarão bloqueados.
              <p className={classes.mTop} />
              Esperamos que você siga na Comunidade MeditaBem. Muitas novidades
              e benefícios ainda vem por aí.
            </p>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {" "}
              <ButtonMedita
                width={"90%"}
                type="button"
                loading={loadingSubscription}
                disabled={loadingSubscription}
                onClick={cancelSubscription}
                className={classes.buttonEnviar}
              >
                Confirmar
              </ButtonMedita>
              <ButtonMedita
                width={"90%"}
                type="button"
                variant="outlined"
                onClick={() => setIsOpen(false)}
                className={classes.buttonEnviar}
              >
                Desistir
              </ButtonMedita>{" "}
            </div>

            <ModalFormResult
              isOpen={modalSuccess}
              closeModal={closeModalSuccess}
              title={modalHeader}
              text={modalBody}
            ></ModalFormResult>
          </Modal>

          <Modal
            isOpen={modalUpdateIsOpen}
            onRequestClose={() => setUpdateIsOpen(false)}
            contentLabel="Alterar dados"
            className={classes.modalCancel}
          >
            <div style={{ display: "flex" }}>
              <IconButton size="large" onClick={() => setUpdateIsOpen(false)}>
                <Close />
              </IconButton>
            </div>
            <h2 className={classes.modalDeleteTitle}>Alterar dados</h2>

            <InputText
              onChange={({ target }) => {
                setName(target.value);
              }}
              type="text"
              label="Nome"
              value={name}
            />

            <InputText
              onChange={({ target }) => {
                setNickName(target.value);
              }}
              type="text"
              label="Apelido"
              value={nickName}
            />

            <ButtonMedita type="button" onClick={() => updateUser(false)}>
              Confirmar
            </ButtonMedita>

            <ModalFormResult
              isOpen={modalSuccess}
              closeModal={closeModalSuccess}
              title={modalHeader}
              text={modalBody}
            ></ModalFormResult>
          </Modal>

          <Modal
            isOpen={modalUpdatePassIsOpen}
            onRequestClose={() => setUpdatePassIsOpen(false)}
            contentLabel="Alterar Senha"
            className={classes.modalCancel}
          >
            <div style={{ display: "flex" }}>
              <IconButton
                size="large"
                onClick={() => setUpdatePassIsOpen(false)}
              >
                <Close />
              </IconButton>
            </div>
            <h2 className={classes.modalDeleteTitle}>Alterar Senha</h2>

            <InputText
              onChange={({ target }) => {
                setPassword(target.value);
              }}
              type={viewPass ? "text" : "password"}
              label="Senha"
              rigthElement={
                <IconButton size="large" onClick={() => setViewPass(!viewPass)}>
                  <Visibility
                    style={{
                      size: "100px",
                      color: "#bfbfbf",
                    }}
                  />
                </IconButton>
              }
            />

            <InputText
              onChange={({ target }) => {
                setConfirmPassword(target.value);
              }}
              type={viewConfirmPass ? "text" : "password"}
              label="Confirmar Senha"
              rigthElement={
                <IconButton
                  size="large"
                  onClick={() => setViewConfirmPass(!viewConfirmPass)}
                >
                  <Visibility
                    style={{
                      size: "100px",
                      color: "#bfbfbf",
                    }}
                  />
                </IconButton>
              }
            />

            <ButtonMedita type="button" onClick={() => updateUser(true)}>
              Confirmar
            </ButtonMedita>

            <ModalFormResult
              isOpen={modalSuccess}
              closeModal={closeModalSuccess}
              title={modalHeader}
              text={modalBody}
            ></ModalFormResult>
          </Modal>

          <ModalFormResult
            isOpen={
              (modalRenewIsOpen || modalUpdatePayment !== null) && modalSuccess
            }
            closeModal={closeModalSuccess}
            title={modalHeader}
            text={modalBody}
          ></ModalFormResult>
        </div>
      </SectionMain>
    </div>
  );
};

export default AccountPageDesktop;
