import React, { useContext, useEffect, useState } from "react";
import "./style.css";
import ButtonMedita from "../ButtonMedita";
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  Link,
  TextField,
  makeStyles,
} from "@material-ui/core";
import api from "../../core/api";
import { useSnackbar } from "notistack";
import InputText from "../InputText";
import { Visibility } from "@material-ui/icons";
import useGoToPage from "../../hooks/useGoToPage";
import { AuthContext } from "../../store/AuthContext";

const useStyles = makeStyles({
  p: {
    fontWeight: "600",
    marginTop: "15px",
    marginBottom: "20px",
    display: "flex",
    flexDirection: "column",
  },
  pTitleSpan: {
    fontWeight: "600",
    marginBottom: 0,
    textAlign: "left",
    fontSize: 20,
  },
  pSpan: {
    fontWeight: "400",
    marginBottom: 0,
    textAlign: "left",
    color: (theme) => (theme.checked ? "#17E5DA" : ""),
  },
  Color: {
    color: "red",
  },
});

const CreateUser = ({ nextStep, formUser }) => {
  const goToPage = useGoToPage();
  const { enqueueSnackbar } = useSnackbar();
  const snackbar = (message) => {
    enqueueSnackbar(`${message}`, {
      variant: "error",
      anchorOrigin: {
        horizontal: "right",
        vertical: "top",
      },
    });
  };
  const { setToken, setData } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const Continue = async () => {
    setLoading(true);

    if (!email || email !== formUser.email) {
      snackbar("Email inválido");
      setLoading(false);
      return;
    }

    try {
      const res = await api.post("/auth/register", formUser);
      if (res.status === 200) {
        if (res.data.token) {
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("id", res.data.user.id);
          localStorage.setItem("name", res.data.user.name);
          localStorage.setItem("nickName", res.data.user.nickName);
          localStorage.setItem("email", res.data.user.email);
          localStorage.setItem("avatarUrl", res.data.user.avatarUrl);
          localStorage.setItem("plano", res.data.user.premium);

          setToken(res.data.token);
          setData(res.data.user);
          nextStep();
        }
      }
    } catch (e) {
      snackbar(
        e.response.data.message || "Ocorreu um erro ao cadastrar usuário"
      );
      return false;
    } finally {
      setLoading(false);
    }
  };

  const classes = useStyles();
  return (
    <div
      style={{
        width: "90%",
        margin: "auto",
        paddingLeft: "10%",
        paddingRight: "10%",
      }}
    >
      <div className={classes.p}>
        <p className={classes.pTitleSpan}>Confirme o email para cadastro</p>
      </div>

      <InputText
        onChange={({ target }) => {
          setEmail(target.value);
        }}
        type="text"
        label="Email"
        value={email}
      />

      <div style={{ textAlign: "center", width: "100%", marginTop: 10 }}>
        <ButtonMedita
          loading={loading}
          disabled={loading}
          type="button"
          onClick={Continue}
          size="large"
        >
          Próximo
        </ButtonMedita>
      </div>
    </div>
  );
};

export default CreateUser;
