import React from "react";
import { makeStyles } from "@material-ui/core";
import SectionMain from "../../../components/SectionMain";
import GiftCardForm from "../../../components/StepperGift/GiftCardForm";

const useStyles = makeStyles({
  container: { padding: "20px 4vw", justifyContent: "center" },
  title: { justifyContent: "center", margin: "20px 0 40px" },
  form: { justifyContent: "center", alignItems: "center" },
  line: {
    width: "42%",
    height: "0.5px",
    border: "solid 0.5px #d8d8d8",
  },
  submit: {
    background: "#316570 !important",
    width: "226px !important",
    minWidth: "226px !important",
    fontSize: "18px !important",
  },
  facebookButton: {
    height: "56px",
    borderRadius: "20px",
    border: "none",
    color: "#282828 !important",
    textTransform: "none",
    width: "600px !important",
    minWidth: "360px !important",
    boxShadow: "none",
  },
  modal: {
    padding: "20px",
    width: "100%",
  },
  link: {
    textDecoration: "underline",
    color: "black",
    fontWeight: "bold",
  },
});

const GiftCardDesktop = () => {
  const classes = useStyles();
  return (
    <SectionMain className={classes.modal} position="center">
      <GiftCardForm />
    </SectionMain>
  );
};

export default GiftCardDesktop;
