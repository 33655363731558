import React, { useContext, useEffect, useState } from "react";
import {
  CircularProgress,
  Grid,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import SectionMain from "../../../components/SectionMain";
import Title from "../../../components/Title";
import useGoToPage from "../../../hooks/useGoToPage";
import curando_magoas from "../../../assets/images/thumbnails/videos/Marcello_Cotrim_Curando_Magoas.jpg";
import api from "../../../core/api";
import { useSnackbar } from "notistack";
import CopyToClipboard from "react-copy-to-clipboard";
import { FileCopy, Visibility } from "@material-ui/icons";

const useStyles = makeStyles({
  root: {
    padding: (theme) =>
      theme.noPadding ? 0 : theme.miniPadding ? "10px 0" : "15px 0",
    justifyContent: (theme) => theme.position,
  },
  container: {
    padding: "20px 4vw",
    justifyContent: "center",

    width: "80%",
    alignSelf: "center",
  },
  banner: {
    background: "#f8f9fa",
    height: 400,
    justifyContent: "space-between",
    padding: "10px 6vw",
  },
  leftSection: { padding: "25px 0", maxHeight: 400 },
  rightSection: { justifyContent: "center" },
  image: { width: "45vw", maxWidth: 400 },
  title: {
    flexDirection: "column",
    letterSpacing: 1,
    justifyContent: "center",
    padding: "0 0 30px",
    fontSize: 25,
  },
  cursive: {
    color: "#008393",
    letterSpacing: 2,
    fontFamily: "adobe-caslon-pro, serif",
    fontStyle: "italic",
    fontSize: 38,
  },
  text: {
    flexDirection: "column",
    alignItems: "center",
    fontSize: 20,
    textAlign: "justify",
  },
  subText: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    height: 195,
    padding: "20px 4vw",
  },
  divImage: {
    background: "#EFEFEF",
    width: "100%",
    height: 250,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    padding: "30px",
  },
  img: { cursor: "pointer" },
  footer: {
    background: "#EFEFEF",
    width: "80%",
    alignSelf: "center",
    margin: "auto",
    padding: "30px",
  },
  contato: {
    background: "#fff",
    borderRadius: 10,
    padding: "10px",
    marginBottom: 10,
  },
  info: {
    width: "100%",
    display: "flex",
    justifyContent: "right",
    fontSize: 14,
  },
});

const RescueGiftDesktop = () => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const goToPage = useGoToPage();
  const [loading, setLoading] = useState(true);
  const [validGift, setValidGift] = useState(null);

  const CheckGiftCard = async () => {
    const token = window.location.search.split("?token=").pop();
    setLoading(true);
    try {
      await api.get(`giftcards/token/${token}`).then(async ({ data }) => {
        setValidGift(data);
      });
    } catch (e) {
      console.log({ e });
      enqueueSnackbar(`${e.response.data.error}` || "Ocorreu um erro", {
        variant: "error",
        anchorOrigin: {
          horizontal: "right",
          vertical: "top",
        },
      });
      setValidGift(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    CheckGiftCard();
  }, []);

  return (
    <SectionMain
      noPadding
      style={{
        display: "flex",
        justifyContent: "center",
        background: "#EFEFEF",
        minHeight: "100vh",
        height: "auto",
      }}
    >
      {validGift?.id && !validGift?.is_rescued ? (
        <>
          <SectionMain className={classes.container}>
            <SectionMain className={classes.title}>
              <Title
                fontWeight="800"
                size="big"
                align="center"
                capitalize="uppercase"
              >
                {"PARABÉNS"}
              </Title>
              <Title fontWeight="800" size="large" align="center">
                Você acaba de receber um <br /> Vale Presente Medita
              </Title>
            </SectionMain>
            <SectionMain className={classes.text}>
              <div style={{ position: "relative" }}>
                <img
                  src={validGift?.card_image}
                  height={200}
                  alt="icon-card"
                  className={classes.border}
                />
              </div>
            </SectionMain>

            <br />

            <div className={classes.footer}>
              <div className={classes.contato}>
                <p style={{ textAlign: "left" }}>
                  <b>De:</b>
                </p>
                <p style={{ textAlign: "left" }}>{validGift?.user?.name}</p>
                <br />
                <p style={{ textAlign: "left" }}>
                  <b>Com a seguinte mensagem:</b>
                </p>
                <p style={{ textAlign: "left" }}>{validGift?.text}</p>
              </div>
              <div className={classes.contato}>
                <div
                  style={{
                    width: "80%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <p style={{ textAlign: "left" }}>
                      <b>Código de Resgate </b>
                    </p>
                    <p style={{ textAlign: "left" }}>
                      {validGift?.redemptionToken}
                      <CopyToClipboard text={validGift?.redemptionToken}>
                        <FileCopy
                          style={{
                            size: "100px",
                            cursor: "pointer",
                          }}
                        />
                      </CopyToClipboard>
                    </p>
                  </div>
                  <div>
                    <p style={{ textAlign: "left" }}>
                      <b>Plano</b>
                    </p>

                    <p style={{ textAlign: "left" }}>
                      {validGift?.description} <br />
                      Válido até 365 dias
                    </p>
                  </div>
                </div>
              </div>
              <div className={classes.contato}>
                <p style={{ textAlign: "left" }}>
                  <b>Sobre o Vale Presente:</b>
                </p>
                <p style={{ textAlign: "left" }}>
                  Este Vale Presente dá 365 dias de acesso premium aos conteúdos
                  do app MeditaBem, a partir da data de ativação do seu código
                  de resgate.
                </p>
              </div>
              <div className={classes.contato}>
                <p style={{ textAlign: "left" }}>
                  <b>Como utilizar seu Vale Presente:</b>
                </p>
                <p style={{ textAlign: "left" }}>
                  Baixe o app MeditaBem no seu celular na loja Play Store ou na
                  App Store. Abra o app e faça o login utilizando o seu e-mail e
                  crie uma senha. No Menu Mais do seu app, clique no item Vale
                  Presente. Pronto, agora é só escolher o campo “Resgatar o seu
                  Vale Presente”, inserir o Código do resgate que informamos
                  neste e-mail e confirmar. O seu plano premium válido por um
                  ano será automaticamente liberado.
                  <br />
                  <br />
                  Estamos muito felizes por fazer parte da sua jornada de
                  autoconhecimento e bem-estar e esperamos que você goste do seu
                  app MeditaBem. Aproveite. Muitas novidades ainda vêm por aí.
                </p>
              </div>
            </div>
          </SectionMain>
        </>
      ) : loading ? (
        <SectionMain className={classes.title}>
          <Title
            fontWeight="800"
            size="big"
            align="center"
            capitalize="uppercase"
          >
            Aguarde...
          </Title>
          <p className={classes.pTitle}>
            <CircularProgress />
          </p>
        </SectionMain>
      ) : (
        <SectionMain className={classes.title}>
          <Title
            fontWeight="800"
            size="big"
            align="center"
            capitalize="uppercase"
          >
            CARTÃO DE PRESENTE INVÁLIDO
          </Title>
        </SectionMain>
      )}
    </SectionMain>
  );
};

export default RescueGiftDesktop;
