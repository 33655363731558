import { Hidden, makeStyles } from "@material-ui/core";
import React from "react";
import { useAppContext } from "../../store/AppContext";
import DrawerMenu from "../Navigation/DrawerMenu";
import TopMenu from "../Navigation/TopMenu";
import SectionMain from "../SectionMain";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles({
  main: {
    justifyContent: "center",
    // maxWidth: "2000px",
  },
});

const AppLayoutUI = ({ children, history }) => {
  const classes = useStyles();
  const { loading } = useAppContext();
  const { pathname } = useLocation();

  return (
    <SectionMain noPadding position="center">
      {pathname !== "/politica-de-privacidade" &&
        pathname !== "/resgate-vale-presente" && (
          <>
            <Hidden only={["xs", "sm"]}>
              <TopMenu />
            </Hidden>
            <Hidden only={["md", "lg", "xl"]}>
              <DrawerMenu />
            </Hidden>
          </>
        )}

      <SectionMain
        noPadding
        position="center"
        className={[
          { paddingTop: pathname !== "/politica-de-privacidade" ? 45 : 0 },
          classes.main,
        ]}
        style={{ overflow: loading ? "hidden" : "auto" }}
      >
        {children}
      </SectionMain>
    </SectionMain>
  );
};

export default AppLayoutUI;
