import React from "react";
import SectionMain from "../../components/SectionMain";
import RescueGiftDesktop from "./desktop";

const RescueGift = () => {
  return (
    <SectionMain noPadding>
      <RescueGiftDesktop />
    </SectionMain>
  );
};

export default RescueGift;
