import React, { useEffect, useState } from "react";
import api from "../../../core/api";
import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import Title from "../../../components/Title";
import { format, parseISO } from "date-fns";
import { StatusPayment } from "../types";
import useGoToPage from "../../../hooks/useGoToPage";

const useStyles = makeStyles({
  container: {
    width: "100%",
    margin: "30px",
  },
  title: { justifyContent: "center", margin: "20px 0 40px" },
  desc: { padding: "40px" },
  form: { marginTop: "20px", marginBottom: "20px" },
  line: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "space-between",
  },
  submit: {
    background: "#316570 !important",
    width: "226px !important",
    minWidth: "226px !important",
    fontSize: "18px !important",
  },
  facebookButton: {
    height: "56px",
    borderRadius: "20px",
    border: "none",
    color: "#282828 !important",
    textTransform: "none",
    width: "600px !important",
    minWidth: "360px !important",
    boxShadow: "none",
  },
  modal: {
    padding: "20px",
    width: "1200px",
  },
  modalCancel: {
    width: "500px",
    background: "#fff",
    margin: "200px auto",
    borderRadius: "13px",
    padding: "20px",
    textAlign: "center",
  },
  link: {
    textDecoration: "underline",
    color: "black",
    fontWeight: "bold",
  },
  linksContainer: {
    backgroundColor: "#EDEDED",
    display: "flex",
    marginTop: 10,
    justifyContent: "space-around",
  },
  buttonEnviar: {
    textTransform: "inherit",
    width: "247px !important",
    marginTop: 5,
  },
  mTop: {
    marginTop: 5,
  },
});

const GiftCardSection = () => {
  const goToPage = useGoToPage();
  const [loading, setLoading] = useState(true);
  const [payments, setPayments] = useState(null);
  const [tablePayment, setTablePayment] = useState([
    "Descrição",
    "Presenteado",
    "Email",
    "Resgatado",
    "Link de Resgate",
    "Valor",
    "Status Pagamento",
    "Data do Pagamento",
  ]);

  const id = localStorage.getItem("id");

  const GetUser = async () => {
    setLoading(true);
    try {
      const { data: result } = await api.get(`giftcards/users/${id}`);

      setPayments({
        payments: result
          ?.sort(function (a, b) {
            return (
              new Date(a.paymentStatus.originalDueDate) -
              new Date(b.paymentStatus.originalDueDate)
            );
          })
          .map((pay) => {
            return {
              id: pay?.paymentStatus?.id,
              originalDueDate: format(
                parseISO(pay?.paymentStatus?.originalDueDate),
                "dd/MM/yyyy"
              ),
              estimatedCreditDate: pay?.paymentStatus?.estimatedCreditDate
                ? format(
                    parseISO(pay?.paymentStatus?.estimatedCreditDate),
                    "dd/MM/yyyy"
                  )
                : null,
              nextDate: pay?.paymentStatus?.estimatedCreditDate,
              description: pay?.paymentStatus?.description,
              status: pay?.paymentStatus?.status,
              value: pay?.paymentStatus?.value,
              cardNumber: pay?.paymentStatus?.creditCard?.creditCardNumber,
              clientPaymentDate: format(
                parseISO(pay?.paymentStatus?.clientPaymentDate),
                "dd/MM/yyyy"
              ),
              userReceived: pay?.gift?.name,
              emailReceived: pay?.gift?.email,
              redemptionToken: pay?.gift?.redemptionToken,
              is_rescued: pay?.gift?.is_rescued,
              userGaveGift: pay?.gift?.user?.name,
              code: pay?.gift?.code,
            };
          }),
      });
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    GetUser();
  }, []);

  const classes = useStyles();

  return (
    !loading &&
    payments?.payments?.length > 0 && (
      <div className={classes.container}>
        <div style={{ width: "100%" }}>
          <Title size="large" fontWeight="700" color="#424242" align="left">
            VALE PRESENTE
          </Title>
          <Divider orientation="horizontal" color="red" width="100%" />
        </div>

        <Title
          size="large"
          fontWeight="400"
          color="#424242"
          align="left"
          marginTop="10px"
        >
          Você enviou {payments?.payments?.length} Vale(s) Presente
        </Title>

        <div style={{ width: "100%" }}>
          <div className={classes.line}>
            <div className={classes.form}>
              <TableContainer>
                <Table aria-label="custom pagination table" className="tabela">
                  <TableHead>
                    <TableRow>
                      {tablePayment.map((p) => (
                        <TableCell align="left">{p}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {payments?.payments?.map((p) => {
                      let price = 0;
                      let format = p.value
                        ?.toString()
                        ?.replace(".", ",")
                        ?.split(",");

                      if (p.value && format.length > 1) {
                        if (
                          p.value?.toString()?.replace(".", ",")?.split(",")[1]
                            .length < 2
                        ) {
                          price = `${p.value?.toString().replace(".", ",")}0`;
                        } else {
                          price = p.value?.toString().replace(".", ",");
                        }
                      } else {
                        price = `${p.value?.toString()},00`;
                      }

                      return (
                        <TableRow key={p.id}>
                          {/* <TableCell align="left" size="small">
                            {p.originalDueDate}
                          </TableCell> */}
                          <TableCell align="left" size="small">
                            {p.description}
                          </TableCell>
                          <TableCell align="left" size="small">
                            {p.userReceived}
                          </TableCell>
                          <TableCell align="left" size="small">
                            {p.emailReceived}
                          </TableCell>
                          <TableCell align="left" size="small">
                            {p.is_rescued ? "SIM" : "NÃO"}
                          </TableCell>
                          <TableCell align="left" size="small">
                            <a
                              style={{ cursor: "pointer" }}
                              className={classes.pLink}
                              onClick={() => {
                                goToPage(
                                  `/resgate-vale-presente?token=${p?.code}`
                                );
                              }}
                            >
                              {p.is_rescued || !p.code
                                ? "-"
                                : `https://medita.com.br/resgate-vale-presente?token=${p?.code?.slice(
                                    0,
                                    15
                                  )}...`}
                            </a>
                          </TableCell>
                          <TableCell align="left" size="small">
                            R$ {price}
                          </TableCell>
                          <TableCell align="left" size="small">
                            {StatusPayment[p.status] || p.status}
                          </TableCell>
                          <TableCell align="left" size="small">
                            {p.clientPaymentDate}
                          </TableCell>
                          {/* {tablePayment.length > 5 &&
                                (p.status === "OVERDUE" ? (
                                  <TableCell align="left" size="small">
                                    <a
                                      style={{
                                        color: "red",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        // setUpdatePayment(p);
                                        console.log("tablePayment", p);
                                      }}
                                    >
                                      Atualizar Pagamento
                                    </a>
                                  </TableCell>
                                ) : (
                                  <TableCell align="left" size="small">
                                    -
                                  </TableCell>
                                ))} */}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default GiftCardSection;
