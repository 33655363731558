import { makeStyles } from "@material-ui/core";
import React from "react";
import card from "../../../../assets/images/giftcards/card.png";
import SectionMain from "../../../../components/SectionMain";

const useStyles = makeStyles({
  container: {
    position: "relative",
    background: "linear-gradient(-180deg, #6eb64b 0.00%, #009daa 100.00%)",
    paddingTop: 10,
    marginBottom: "5%",
  },
  banner: {
    width: "100%",
    margin: "auto",
    marginTop: 20,
    marginBottom: 20,
  },
  viewImage: {
    width: "60%",
    margin: "auto",
  },
  leftSection: {
    margin: "auto",
    width: "90%",
  },
  title: {
    fontFamily: "Century Gothic",
    fontSize: "18px",
    fontWeight: "bold",
    fontStyle: "normal",
    textAlign: "left",
    color: "#183b56",
  },
  titleTwo: {
    fontFamily: "Century Gothic",
    fontSize: "35px",
    fontWeight: "normal",
    fontStyle: "normal",
    textAlign: "left",
    color: "#36b37e",
  },
  description: {
    fontFamily: "Century Gothic",
    fontSize: "30px",
    fontWeight: "normal",
    fontStyle: "normal",
    textAlign: "center",
    color: "#fff",
  },
  root: {
    flexDirection: "column",
    alignItems: "flex-start",
    marginTop: 20,
    marginRight: 10,
    textAlign: "left",
  },
  avatar: {
    width: "64px",
    height: "64px",
    borderRadius: "16px",
    background: "#6eb64b",
    background: "linear-gradient(180deg,  #6eb64b 0.00%, #009daa 100.00%)",
  },
  titleItems: {
    fontFamily: "Century Gothic",
    fontSize: "20px",
    fontWeight: "bold",
    fontStyle: "normal",
    textAlign: "left",
    color: "#183b56",
    marginTop: 20,
  },
  descItems: {
    width: "85%",
    fontFamily: "Century Gothic",
    fontSize: "16px",
    fontWeight: "normal",
    fontStyle: "normal",
    textAlign: "left",
    color: "#5a7184",
  },
  align: {
    textAlign: "left",
    marginBottom: 20,
    marginLeft: 10,
  },
  viewCenter: {
    display: "flex",
    alignItems: "center",
    marginTop: 10,
  },
  margin: { marginTop: 50 },
  color: { color: "#fff" },
});

const SectionFour = () => {
  const classes = useStyles();

  return (
    <SectionMain noPadding className={classes.container}>
      <SectionMain className={classes.banner}>
        <img src={card} className={classes.viewImage} />
        <SectionMain className={classes.leftSection}>
          <div className={classes.description}>
            Tudo o que você precisa para transformar a rotina de quem você ama
            em uma jornada de paz e equilíbrio está aqui.
          </div>
        </SectionMain>
      </SectionMain>
    </SectionMain>
  );
};

export default SectionFour;
