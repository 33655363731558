export const navButtons = [
  {
    title: "meditabem",
    link: "/",
    path: "",
  },
  {
    title: "experimente",
    link: "/experimente",
    path: "",
  },
  {
    title: "Vale Presente",
    link: "/vale-presente",
    path: "",
  },
  {
    title: "parceiros",
    link: "/parceiros",
    path: "",
  },
  // {
  //   title: "baixe aqui",
  //   link: "https://play.google.com/store/apps/details?id=com.newmeditabemapp",
  //   path: "",
  // },
  {
    title: "contato",
    link: "/contato",
    path: "",
  },
  {
    title: "assinatura",
    link: "/medita-bem-planos",
    path: "",
  },
];

export const navButtonsMenu = [
  {
    title: "meditaBem",
    link: "/",
    path: "",
  },
  // {
  //   title: 'sobre nós',
  //   link: '/sobre-nos',
  //   path: '',
  // },
  {
    title: "categorias",
    link: "/experimente",
    path: "",
  },
  {
    title: "Meditações",
    link: "/experimente?meditacoes",
    path: "meditacoes=",
  },
  {
    title: "yoga",
    link: "/experimente?yoga",
    path: "yoga=",
  },
  {
    title: "exercícios",
    link: "/experimente?exercicios",
    path: "exercicios=",
  },
  {
    title: "mantras",
    link: "/experimente?mantras",
    path: "mantras=",
  },
  // {
  // 	title: 'categorias',
  // 	link: '/categorias', path:''
  // },
  {
    title: "parceiros",
    link: "/parceiros",
    path: "",
  },
  {
    title: "contato",
    link: "/contato",
    path: "",
  },
];

export const navButtonsMenuDesktop = [
  {
    title: "MeditaBem",
    link: "/",
    path: "",
  },
  {
    title: "Experimente",
    link: "/experimente",
    path: "",
  },
  {
    title: "Vale Presente",
    link: "/vale-presente",
    path: "",
  },
  {
    title: "Parceiros",
    link: "/parceiros",
    path: "",
  },
  // {
  //   title: "Download",
  //   link: "/",
  //   path: "",
  // },
  {
    title: "Planos",
    link: "/medita-bem-planos",
    path: "",
    bold: "normal",
  },
  {
    title: "Contato",
    link: "/contato",
    path: "",
    bold: "normal",
  },
];
