import React from "react";
import "./style.css";
import "./styleCard.css";
import { makeStyles } from "@material-ui/core";
import android from "../../assets/images/logo_playstore.png";
import banner_first from "../../assets/images/home/iphone.png";
import apple from "../../assets/images/logo_applestore.png";
import Title from "../Title";

import ButtonMedita from "../ButtonMedita";
import useGoToPage from "../../hooks/useGoToPage";

const useStyles = makeStyles({
  container: {
    width: "100%",
    margin: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  line: {
    fontWeight: "600",
    color: "#030102",
    fontSize: "25px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "space-between",
  },
  pTitle: {
    fontWeight: "600",
    color: "#030102",
    fontSize: "30px",
  },
  pBody: {
    margin: 0,
    fontWeight: "400",
    color: "#030102",
    fontSize: "25px",
  },
  pInfos: {
    fontWeight: "600",
    color: "#030102",
    fontSize: "25px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "space-between",
  },
  pDesc: {
    fontWeight: "400",
    color: "#030102",
    fontSize: "25px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "space-between",
  },
  pDescfinish: {
    fontWeight: "400",
    color: "#030102",
    fontSize: "15px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "space-between",
  },
  row: {
    width: "100%",
    fontWeight: "600",
    marginTop: "15px",
    display: "flex",
    flexDirection: "row",
    backgroundColor: "red",
  },

  CreditCard: {
    backgroundColor: "#fff",
    color: "#000",
    borderRadius: "10px",
    width: "50%",
    display: "flex",
    justifyContent: "center",
    fontSize: "40px",
  },
  stepCard: {
    display: "flex",
    marginTop: "15px",
  },
  stepLogo: {
    display: "flex",
    alignItems: "center",
    marginTop: "15px",
  },
  stepButton: {
    width: 25,
    height: 25,
    marginRight: "10px",
    display: "flex",
    padding: 10,
    fontSize: "1em",
    alignItems: "center",
    borderRadius: "100%",
    justifyContent: "center",
    backgroundColor: "blue",
    color: "#fff",
  },
  stepText: {
    fontWeight: "600",
    color: "#030102",
    fontSize: 18,
  },
  stepTotal: {
    fontWeight: "700",
    color: "#030102",
    fontSize: "30px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "space-between",
  },
});

const FinishCard = () => {
  const goToPage = useGoToPage();
  const classes = useStyles();

  return (
    <div className="form" style={{ width: "100%" }}>
      <div>
        <div>
          <Title size="xbig" fontWeight="700" color="#424242" align="left">
            VAMOS PRATICAR?
          </Title>
          <Title size="large" fontWeight="400" color="#424242" align="left">
            Para começar a usar o sistema MeditaBem você deve seguir as etapas
            abaixo!
          </Title>

          <div className={classes.stepCard}>
            <div className={classes.line}>
              <div className={classes.stepCard}>
                <div className={classes.stepButton}>1</div>
                <div>
                  <span className={classes.pDescfinish}>Etapa 1</span>
                  <span className={classes.pDescfinish}>
                    Baixe o aplicativo no seu celular através das lojas abaixo
                  </span>
                </div>
              </div>
              <div className={classes.stepLogo}>
                <img
                  width={75}
                  src={android}
                  alt="Logo Play Store"
                  style={{ padding: "20px", marginTop: "15px" }}
                />
                <div>
                  <span className={classes.stepText}>ANDROID</span>
                  <span className={classes.pDescfinish}>
                    <ButtonMedita
                      backgroundColor="#316570"
                      width={150}
                      onClick={() =>
                        goToPage(
                          "https://play.google.com/store/apps/details?id=com.newmeditabemapp"
                        )
                      }
                    >
                      CLIQUE AQUI
                    </ButtonMedita>
                  </span>
                </div>
              </div>
              <div className={classes.stepLogo}>
                <img
                  width={75}
                  src={apple}
                  alt="Logo Apple Store"
                  style={{ padding: "20px", marginTop: "15px" }}
                />
                <div>
                  <span className={classes.stepText}>IOS</span>
                  <span className={classes.pDescfinish}>
                    <ButtonMedita
                      backgroundColor="#316570"
                      width={150}
                      onClick={() =>
                        goToPage(
                          "https://apps.apple.com/br/app/meditabem/id1533068675?l=en"
                        )
                      }
                    >
                      CLIQUE AQUI
                    </ButtonMedita>
                  </span>
                </div>
              </div>
            </div>
            <div className={classes.line}>
              <div className={classes.stepCard}>
                <div className={classes.stepButton}>2</div>
                <div>
                  <span className={classes.pDescfinish}>Etapa 2</span>
                  <span className={classes.pDescfinish}>
                    Após baixar o app na loja, basta fazer o login com o e-mail
                    e senha que você cadastrou e desfrutar de todos os
                    conteúdos do MeditaBem.
                  </span>
                </div>
              </div>
              <div className={classes.stepLogo}>
                <img
                  width={400}
                  src={banner_first}
                  alt="Logo Apple Store"
                  style={{ padding: "20px", marginTop: "15px" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinishCard;
