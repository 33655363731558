import React, { useEffect, useState } from "react";
import "./style.css";
import "./styleCard.css";
import { CircularProgress, Divider, makeStyles } from "@material-ui/core";
import code from "../../assets/images/code.png";
import pending from "../../assets/icons/pending.png";
import check from "../../assets/icons/check.png";
import error from "../../assets/icons/error.png";
import Title from "../Title";

import ButtonMedita from "../ButtonMedita";
import useGoToPage from "../../hooks/useGoToPage";
import {
  amex_brand,
  card,
  diners_brand,
  maestro_brand,
  mastercard_brand,
  unionpay_brand,
  visa_brand,
  discover_brand,
  jcb_brand,
} from "../../assets/icons/svgs";
import api from "../../core/api";

const useStyles = makeStyles({
  container: {
    width: "100%",
    margin: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  line: {
    fontWeight: "600",
    color: "#030102",
    fontSize: "25px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "space-between",
  },
  pTitle: {
    fontWeight: "600",
    color: "#030102",
    fontSize: "30px",
  },
  pBody: {
    margin: 0,
    fontWeight: "400",
    color: "#030102",
    fontSize: "25px",
  },
  pInfos: {
    fontWeight: "600",
    color: "#030102",
    fontSize: "25px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "space-between",
  },
  pLink: {
    fontWeight: "600",
    color: "#030102",
    fontSize: "15px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "space-between",
  },
  pDesc: {
    fontWeight: "400",
    color: "#030102",
    fontSize: "25px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "space-between",
  },
  pDescfinish: {
    fontWeight: "400",
    color: "#030102",
    fontSize: "15px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "space-between",
  },
  row: {
    width: "100%",
    fontWeight: "600",
    marginTop: "15px",
    display: "flex",
    flexDirection: "row",
    backgroundColor: "red",
  },

  CreditCard: {
    backgroundColor: "#fff",
    color: "#000",
    borderRadius: "10px",
    width: "50%",
    display: "flex",
    justifyContent: "center",
    fontSize: "40px",
  },
  stepCard: {
    display: "flex",
    marginTop: "15px",
  },
  stepLogo: {
    display: "flex",
    alignItems: "center",
    marginTop: "15px",
  },
  stepButton: {
    width: 25,
    height: 25,
    marginRight: "10px",
    display: "flex",
    padding: 10,
    fontSize: "1em",
    alignItems: "center",
    borderRadius: "100%",
    justifyContent: "center",
    backgroundColor: "blue",
    color: "#fff",
  },
  stepText: {
    fontWeight: "600",
    color: "#030102",
    fontSize: 18,
  },
  stepTotal: {
    fontWeight: "700",
    color: "#030102",
    fontSize: "30px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "space-between",
  },
});

const statusPaymentInfos = [
  {
    color: "#F1A431",
    icon: pending,
    title: "Transação em Processamento",
    text: "Por favor, aguarde a confirmação!",
    status: "PENDENTE",
  },
  {
    color: "#F14831",
    icon: error,
    title: "Pagamento Recusado",
    text: "Por favor, entre em contato com seu cartão de crédito!",
    status: "RECUSADO",
  },
  {
    color: "#34A853",
    icon: check,
    title: "Obrigado!",
    text: "Sua assinatura foi confirmada!",
    status: "PAGO",
  },
];

const SuccessCard = ({ subsResponse, nextStep }) => {
  const goToPage = useGoToPage();
  const [dataSubs, setDataSubs] = useState(null);
  const [statusPayment, setPayment] = useState(statusPaymentInfos[0]);
  const [credImg, setCredImg] = useState(null);
  const [loading, setLoading] = useState(null);

  const updPlans = async () => {
    setLoading(true);
    await api
      .get(`giftcards/payments/${subsResponse?.giftcards?.id}`)
      .then(async ({ data: response }) => {
        if (response.status === "CONFIRMED") {
          setPayment(statusPaymentInfos[2]);
          setDataSubs(response);
        }
      })
      .catch((err) => {
        console.error("err", err);
      });
    setLoading(false);
  };

  const FormDataAsaas = () => {
    if (dataSubs.creditCard?.creditCardBrand) {
      switch (dataSubs.creditCard?.creditCardBrand?.toLowerCase()) {
        case "american express":
          setCredImg(amex_brand);
          break;
        case "visa":
          setCredImg(visa_brand);
          break;
        case "diners":
          setCredImg(diners_brand);
          break;
        case "discover":
          setCredImg(discover_brand);
          break;
        case "jcb" || "jcb15":
          setCredImg(jcb_brand);
          break;
        case "maestro":
          setCredImg(maestro_brand);
          break;
        case "mastercard":
          setCredImg(mastercard_brand);
          break;
        case "unionpay":
          setCredImg(unionpay_brand);
          break;
        default:
          setCredImg(card);
          break;
      }
    }
  };

  useEffect(() => {
    if (dataSubs) FormDataAsaas();
  }, [dataSubs]);

  useEffect(() => {
    if (subsResponse && statusPayment?.status !== "PAGO") updPlans();
  }, [loading]);

  const classes = useStyles();

  return (
    <div className="form" style={{ width: "100%" }}>
      <div>
        <div
          className="form"
          style={{
            width: "700px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: "75px",
                height: "75px",
                backgroundColor: statusPayment?.color || "#F1A431",
                borderRadius: "100%",
                border: "solid 10px #EDEDED",
                zIndex: 999,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                width={"50%"}
                height={"50%"}
                src={statusPayment?.icon || pending}
                alt="Logo Status"
              />
            </div>{" "}
          </div>

          <div
            style={{
              width: "75%",
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
              marginTop: "-40px",
              padding: 15,
              borderTopRightRadius: 15,
              borderTopLeftRadius: 15,
              backgroundColor: "#EDEDED",
            }}
            className="bodySuccessTop"
          >
            {dataSubs ? (
              <>
                <p className={classes.pTitle}>
                  {subsResponse?.giftcards?.description}
                  <p className={classes.pBody}>{statusPayment?.text}</p>
                </p>
                <div>
                  {" "}
                  <div className={classes.container}>
                    <span className={classes.pDesc}>Presente para</span>
                    <span className={classes.pInfos}>
                      {subsResponse?.giftcards?.name}
                    </span>
                  </div>
                  <div className={classes.container}>
                    <span className={classes.pDesc}>Plano</span>
                    <span className={classes.pInfos}>
                      {subsResponse?.giftcards?.description}
                    </span>
                  </div>
                  <div className={classes.container}>
                    <span className={classes.pDesc}>Link de Resgate:</span>
                    <a
                      style={{ cursor: "pointer" }}
                      className={classes.pLink}
                      onClick={() => {
                        goToPage(
                          `/resgate-vale-presente?token=${dataSubs?.code}`
                        );
                      }}
                    >
                      {`https://medita.com.br/resgate-vale-presente?token=${dataSubs?.code?.slice(
                        0,
                        15
                      )}...`}
                    </a>
                  </div>
                  <Divider orientation="horizontal" color="red" width="100%" />
                  <div className={classes.container}>
                    <span className={classes.pInfos}>Total</span>
                    <span className={classes.pInfos}>
                      R$ {subsResponse?.value}
                    </span>
                  </div>
                  {dataSubs?.creditCard?.creditCardNumber && (
                    <div
                      style={{
                        height: 70,
                        backgroundColor: "#fff",
                        padding: 10,
                        borderRadius: 20,
                        alignSelf: "center",
                        margin: 15,
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                    >
                      <div id="ccicon">
                        <img
                          width={80}
                          height={"auto"}
                          src={credImg}
                          alt="Logo Status"
                        />
                      </div>

                      <div>
                        <Title size="large" fontWeight="700" align="left">
                          {dataSubs.billingType}
                        </Title>
                        <Title size="large" fontWeight="700" align="left">
                          {dataSubs?.creditCard?.creditCardBrand} -{" "}
                          {dataSubs?.creditCard?.creditCardNumber}
                        </Title>
                      </div>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <>
                <p className={classes.pTitle}>Aguarde...</p>{" "}
                <p className={classes.pTitle}>
                  <CircularProgress />
                </p>
              </>
            )}
          </div>

          <div
            style={{
              width: "75%",
              height: 150,
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
              padding: 15,
              borderTop: "1px dashed #B8B8B8",
              backgroundColor: "#EDEDED",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            className="bodySuccessBot"
          >
            <span className={classes.pInfos}>
              <img
                width={135}
                src={code}
                alt="Código de barras"
                style={{ padding: "20px" }}
              />
            </span>
            <span className={classes.pInfos}>
              <div
                style={{
                  border: `3px solid ${statusPayment?.color}`,
                  color: statusPayment?.color,
                  borderRadius: "10px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "40px",
                }}
              >
                {statusPayment?.status}
              </div>
            </span>
          </div>
          {dataSubs && (
            <ButtonMedita
              type="button"
              onClick={nextStep}
              size="medium"
              style={{ marginTop: 15 }}
            >
              Vamos praticar?
            </ButtonMedita>
          )}
        </div>
      </div>
    </div>
  );
};

export default SuccessCard;
