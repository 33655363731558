import { Avatar, makeStyles } from "@material-ui/core";
import React from "react";
import simbolo from "../../../../assets/images/home/simbolo.png";
import cards from "../../../../assets/images/giftcards/cards.png";
import SectionMain from "../../../../components/SectionMain";
import ButtonMedita from "../../../../components/ButtonMedita";

const useStyles = makeStyles({
  container: {
    paddingBottom: 15,
  },
  sectionInput: {
    marginTop: 20,
    margin: "auto",
    padding: "5px 5px",
    borderRadius: "30px 30px",
  },
  leftSectionTwo: {
    height: "100%",
    maxWidth: "80%",
    margin: "auto",
    marginTop: 20,
  },
  divTop: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  titleSectionTwo: {
    fontFamily: "Century Gothic",
    fontSize: "30px",
    fontWeight: "normal",
    fontStyle: "normal",
    textAlign: "center",
    color: "#3bad76",
    display: "flex",
  },
  viewCenter: { width: "88%", margin: "auto" },
  avatarTop: {
    width: "64px",
    height: "64px",
    borderRadius: "16px",
    margin: "auto",
  },
  descriptionTwo: {
    fontFamily: "Century Gothic",
    fontSize: "30px",
    fontWeight: "normal",
    fontStyle: "normal",
    textAlign: "left",
    color: "#5A7184",
  },
  image: {
    width: "95%",
    marginTop: "-10%",
  },
});

const SectionTwo = ({ setButton }) => {
  const classes = useStyles();

  return (
    <SectionMain noPadding className={classes.container}>
      <SectionMain noPadding item className={classes.leftSectionTwo}>
        <div className={classes.viewCenter}>
          <div className={classes.divTop}>
            <Avatar
              className={classes.avatarTop}
              alt={`icon-simbolo`}
              src={simbolo}
            />
            <span className={classes.descriptionTwo}>Um presente,</span>
            <span className={classes.titleSectionTwo}>
              Cinco estilos exclusivos
            </span>
            <br />
          </div>
        </div>
      </SectionMain>
      <img src={cards} className={classes.image} alt="Medita app celular" />
      <div className={classes.sectionInput}>
        <ButtonMedita
          style={{ width: "auto", whiteSpace: "nowrap", textTransform: "none" }}
          onClick={() => {
            setButton(true);
          }}
        >
          {"Escolher modelo"}
        </ButtonMedita>
      </div>
    </SectionMain>
  );
};

export default SectionTwo;
