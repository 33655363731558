import React, { useContext, useEffect, useState } from "react";
import { Stepper } from "react-form-stepper";
import StepOneCard from "./StepOneCard";
import StepTwoCard from "./StepTwoCard";
import { AuthContext } from "../../store/AuthContext";
import { useQueryParams } from "../../hooks/useQueryParams";
import api from "../../core/api";
import PersonalDetails from "./PersonalDetails";
import CreateUser from "./CreateUser";
import FinishCard from "./FinishCard";
import { useSnackbar } from "notistack";
import { Avatar, CircularProgress, makeStyles } from "@material-ui/core";
import SectionMain from "../SectionMain";
import simbolo from "../../assets/images/home/simbolo.png";
import "./index.css";

const useStyles = makeStyles({
  container: {
    paddingBottom: 15,
    background: "#F4F4F4",
  },
  sectionInput: {
    marginTop: 20,
    margin: "auto",
    padding: "5px 5px",
    borderRadius: "30px 30px",
  },
  leftSectionTwo: {
    height: "100%",
    margin: "auto",
    marginTop: 20,
  },
  divTop: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  titleSectionTwo: {
    fontFamily: "Century Gothic",
    fontSize: "30px",
    fontWeight: "normal",
    fontStyle: "normal",
    textAlign: "center",
    color: "#3bad76",
    display: "flex",
  },
  viewCenter: { width: "88%", margin: "auto" },
  avatarTop: {
    width: "60px",
    height: "60px",
    borderRadius: "16px",
    margin: "auto",
  },
  descriptionTwo: {
    fontFamily: "Century Gothic",
    fontSize: "20px",
    fontWeight: "normal",
    fontStyle: "normal",
    textAlign: "left",
    color: "#5A7184",
  },
  image: {
    width: "95%",
    marginTop: "-10%",
  },
});

const GiftCardForm = () => {
  const classes = useStyles();
  const token = localStorage.getItem("token");
  const [subsResponse, setSubsResponse] = useState(null);
  const [form, setForm] = useState("");
  const [formUser, setFormUser] = useState("");
  const [state, setState] = useState({
    step: 1,
  });
  const [loading, setLoading] = useState(false);
  const [cards, setCards] = useState([]);
  const [itemSelect, setItemSelect] = useState(null);

  const { enqueueSnackbar } = useSnackbar();
  const snackbar = (message) => {
    enqueueSnackbar(`${message}`, {
      variant: "error",
      anchorOrigin: {
        horizontal: "right",
        vertical: "top",
      },
    });
  };

  const nextStep = () => {
    if (state.step === 1 && !itemSelect)
      return snackbar("Selecione o Cartão de Presente.");
    setState({ ...state, step: state.step === 3 ? 2 : state.step + 1 });
  };

  const prevStep = () => {
    setState({ ...state, step: state.step - 1 });
  };

  const { User, setData } = useContext(AuthContext);
  const params = useQueryParams();
  const GetUserByToken = async () => {
    if (params) {
      try {
        await api.get("/auth/redirect").then(async (res) => {
          localStorage.setItem("id", res.data.user.id);
          localStorage.setItem("name", res.data.user.name);
          localStorage.setItem("nickName", res.data.user.nickName);
          localStorage.setItem("email", res.data.user.email);
          localStorage.setItem("avatarUrl", res.data.user.avatarUrl);
          localStorage.setItem("plano", res.data.user.premium);

          User(res.data.token);
          setData(res.data.user);
        });
      } catch (error) {}
    }
  };

  useEffect(() => {
    if (params?.token) {
      localStorage.setItem("token", params?.token);
      GetUserByToken(params?.token);
    }
  }, [params]);

  const renderContentToken = () => {
    switch (state.step) {
      case 1:
        return <StepOneCard setForm={setForm} nextStep={nextStep} />;
      case 2:
        return (
          <StepTwoCard
            form={{ ...form, card_image: itemSelect }}
            nextStep={nextStep}
            prevStep={prevStep}
            subsResponse={subsResponse}
            setSubsResponse={setSubsResponse}
          />
        );
      case 3:
        return <FinishCard />;
      default:
        return null;
    }
  };

  const renderContent = () => {
    switch (state.step) {
      case 1:
        return <StepOneCard setForm={setForm} nextStep={nextStep} />;
      case 2:
        return (
          <PersonalDetails nextStep={nextStep} setFormUser={setFormUser} />
        );
      case 3:
        return <CreateUser nextStep={nextStep} formUser={formUser} />;

      case 4:
        return (
          <StepTwoCard
            form={{ ...form, card_image: itemSelect }}
            nextStep={nextStep}
            prevStep={prevStep}
            subsResponse={subsResponse}
            setSubsResponse={setSubsResponse}
          />
        );
      case 5:
        return <FinishCard />;
      default:
        return null;
    }
  };

  const steps = token
    ? [
        { label: "Vale Presente" },
        { label: "Pagamento" },
        { label: "Conclusão" },
      ]
    : [
        { label: "Vale Presente" },
        { label: "Criar Conta" },
        { label: "Verificação" },
        { label: "Pagamento" },
        { label: "Conclusão" },
      ];

  const getCards = async () => {
    setLoading(true);
    try {
      await api.get(`/giftcards/cards`).then(async ({ data }) => {
        setCards(data);
      });
    } catch (e) {
      // snackbar("Ops.. algo de errado aconteceu. Tente novamente mais tarde!");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCards();
  }, []);

  return (
    <>
      {state.step === 1 && (
        <SectionMain noPadding className={classes.container}>
          <SectionMain noPadding item className={classes.leftSectionTwo}>
            <div className={classes.viewCenter}>
              <div className={classes.divTop}>
                <Avatar
                  className={classes.avatarTop}
                  alt={`icon-simbolo`}
                  src={simbolo}
                />
                <span className={classes.descriptionTwo}>Escolha o Cartão</span>
                <span className={classes.titleSectionTwo}>
                  Ideal para presentear
                </span>
                <br />
              </div>
            </div>

            {loading ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CircularProgress color="#fff" />
              </div>
            ) : (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexWrap: "nowrap",
                  overflowX: "auto",
                }}
              >
                {cards.length > 0 &&
                  cards.map((item) => (
                    <div style={{ position: "relative", marginRight: 20 }}>
                      <img
                        src={item}
                        alt="icon-card"
                        height={itemSelect === item ? 280 : 260}
                        className={classes.border}
                        onClick={() => {
                          setItemSelect(item);
                        }}
                      />
                    </div>
                  ))}
              </div>
            )}
          </SectionMain>
        </SectionMain>
      )}
      <div
        style={{
          backgroundColor: "transparent",
          width: "100%",
          maxWidth: "1200px",
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Stepper
          steps={steps}
          activeStep={state.step - 1}
          styleConfig={{
            activeBgColor: "#8BCD98",
            activeTextColor: "#fff",
            inactiveBgColor: "#D9D9D9",
            inactiveTextColor: "#fff",
            completedBgColor: "#8BCD98",
            completedTextColor: "#fff",
            size: "3em",
            border: "none",
          }}
          className={"stepper form"}
        />
        {token ? renderContentToken() : renderContent()}
      </div>
    </>
  );
};

export default GiftCardForm;
