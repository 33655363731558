import { Hidden, makeStyles, styled } from "@material-ui/core";
import React, { useState } from "react";
import fundo from "../../../../assets/images/home/banner_home.png";
import iphone from "../../../../assets/images/home/iphone.png";
import simbolo from "../../../../assets/images/home/simbolo.png";
import SectionMain from "../../../../components/SectionMain";
import InputText from "../../../../components/InputText";
import { useSnackbar } from "notistack";
import api from "../../../../core/api";
import useGoToPage from "../../../../hooks/useGoToPage";
import ButtonMedita from "../../../../components/ButtonMedita";

const useStyles = makeStyles({
  container: {
    background:
      "linear-gradient(180deg, rgba(250,251,251,0 ) 0.00%, #ececec 100.00%)",
    // backgroundImage: `url(${fundo})`,
    // backgroundPosition: "center",
    // backgroundSize: "contain",
    // backgroundRepeat: "no-repeat",
    // backgroundPosition: "right",
    borderRadius: "0% 0% 150% 150%/0% 0% 40% 40%",
    minHeight: "500px",
    marginTop: "20px",
    position: "relative",
    overflow: "hidden",
  },
  viewImage: {
    minWidth: "600px",
    minHeight: "540px",
    pointerEvents: "none",
    position: "absolute",
    right: "-10%",
    width: "60%",
  },
  viewImagetwo: {
    maxHeight: "500px",
    pointerEvents: "none",
    position: "absolute",
    right: "15%",
    bottom: "-10%",
  },
  banner: {
    maxWidth: "80%",
    alignItems: "flex-start",
    justifyContent: "space-between",
    margin: "auto",
    marginTop: 20,
  },
  leftSection: {
    height: "100%",
    width: "45%",
    paddingTop: 50,
    display: "flex",
  },
  title: {
    fontFamily: "Century Gothic",
    fontSize: "20px",
    fontWeight: "normal",
    fontStyle: "normal",
    textAlign: "left",
    color: "#6f7072",
  },
  titleTwo: {
    fontFamily: "Century Gothic",
    fontSize: "50px",
    fontWeight: "normal",
    fontStyle: "normal",
    textAlign: "left",
    color: "#3bad76",
  },
  description: {
    fontFamily: "Century Gothic",
    fontSize: "20px",
    fontWeight: "normal",
    fontStyle: "normal",
    textAlign: "left",
    color: "#6f7072",
  },
  sectionInput: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    marginTop: 20,
    background: "#fff",
    padding: "5px 5px",
    borderRadius: "30px 30px",
  },
  InputHome: {
    border: "none",
    marginTop: 20,
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "300",
    fontStyle: "normal",
    textAlign: "left",
    color: "#1b1b1b",
  },
});

const SectionOne = () => {
  const classes = useStyles();
  const goToPage = useGoToPage();
  const [email, setEmail] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const token = localStorage.getItem("token");

  const CheckEmailExists = async () => {
    try {
      await api.post("/auth/register/exists", { email }).then(async (res) => {
        goToPage("/cadastre-se");
      });
    } catch (e) {
      console.error({ e });
      enqueueSnackbar(`${e.response.data.message}` || "Ocorreu um erro", {
        variant: "error",
        anchorOrigin: {
          horizontal: "right",
          vertical: "top",
        },
      });
      return false;
    }
  };

  return (
    <div style={{ position: "relative" }}>
      {/* style={{ backgroundColor: "red", color: "red" }} */}
      <SectionMain noPadding className={classes.container}>
        <SectionMain className={classes.banner}>
          <SectionMain className={classes.leftSection}>
            <div className={classes.title}>
              Bem-vindo ao MeditaBem. <br />
              <span className={classes.titleTwo}>
                Seu refúgio de paz e equilíbrio.
              </span>
              <br />
              <span className={classes.description}>
                Experimente o MeditaBem por 14 dias grátis e transforme seu
                estado de espírito. Acesse conteúdos que aliviam o estresse e a
                ansiedade. Aprenda práticas que renovam seu bem-estar físico e
                mental.
              </span>
              {!token && (
                <div className={classes.sectionInput}>
                  <InputText
                    onChange={({ target }) => {
                      setEmail(target.value);
                      localStorage.setItem("emailTemp", target.value);
                    }}
                    type="text"
                    label="Insira seu e-mail"
                    className={classes.InputHome}
                  />
                  <ButtonMedita onClick={() => CheckEmailExists()}>
                    {"Começar agora"}
                  </ButtonMedita>
                </div>
              )}
            </div>
          </SectionMain>
        </SectionMain>

        <img src={fundo} className={classes.viewImage} />
      </SectionMain>
      <img alt="Iphone" src={iphone} className={classes.viewImagetwo} />
    </div>
  );
};

export default SectionOne;
