import { makeStyles } from "@material-ui/core";
import React from "react";
import fundo from "../../../../assets/images/giftcards/home.png";
import SectionMain from "../../../../components/SectionMain";
import ButtonMedita from "../../../../components/ButtonMedita";
import useGetPlans from "../../../../hooks/useGetPlans";

const useStyles = makeStyles({
  container: {
    backgroundImage: `url(${fundo})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "inherit",
    paddingTop: "20px",
    paddingBottom: "20px",
    // opacity: 0.7,
  },
  viewImage: {
    minWidth: "600px",
    minHeight: "540px",
    pointerEvents: "none",
    position: "absolute",
    right: "-10%",
    width: "60%",
  },
  viewImagetwo: {
    maxHeight: "500px",
    pointerEvents: "none",
    position: "absolute",
    right: "15%",
    bottom: "-10%",
  },
  banner: {
    maxWidth: "80%",
    alignItems: "flex-start",
    justifyContent: "space-between",
    margin: "auto",
    marginTop: 20,
  },
  leftSection: {
    height: "100%",
    width: "45%",
    paddingTop: 50,
    display: "flex",
  },
  title: {
    fontFamily: "Century Gothic",
    fontSize: "20px",
    fontWeight: "normal",
    fontStyle: "normal",
    textAlign: "left",
    color: "#fff",
    opacity: 1,
  },
  titleTwo: {
    fontFamily: "Century Gothic",
    fontSize: "50px",
    fontWeight: "normal",
    fontStyle: "normal",
    textAlign: "left",
    color: "#fff",
  },
  description: {
    fontFamily: "Century Gothic",
    fontSize: "20px",
    fontWeight: "normal",
    fontStyle: "normal",
    textAlign: "left",
    color: "#fff",
  },
  sectionInput: {
    marginTop: 20,
    padding: "5px 5px",
    borderRadius: "30px 30px",
  },
  InputHome: {
    border: "none",
    marginTop: 20,
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "300",
    fontStyle: "normal",
    textAlign: "left",
    color: "#1b1b1b",
  },
});

const SectionOne = ({ setButton }) => {
  const classes = useStyles();
  const { plans: plansApi } = useGetPlans();

  return (
    <div>
      <SectionMain noPadding className={classes.container}>
        <SectionMain className={classes.banner}>
          <SectionMain className={classes.leftSection}>
            <div className={classes.title}>
              O presente que tranforma vidas <br />
              <span className={classes.titleTwo}>
                Vale um ano inteiro de equilíbrio, paz e autocuidado.
              </span>
              <br />
              <br />
              <span className={classes.description}>
                Surpreenda quem você ama com um presente único: 365 dias de
                meditações guiadas, exercícios revitalizantes e práticas que
                elevam o bem-estar.
                <br />
                <br />
                Com o MeditaBem, você oferece muito mais que um presente você
                transforma rotinas e ajuda a criar dias mais leves e
                equilibrados.
                <br />
                <br />
                <b>
                  Oferta exclusiva: R${plansApi[1]?.value || "-"} por um ano de
                  acesso premium.
                </b>
              </span>
              <br />
              <br />
              <div className={classes.sectionInput}>
                <ButtonMedita
                  style={{
                    width: "70%",
                    whiteSpace: "nowrap",
                    textTransform: "none",
                  }}
                  onClick={() => {
                    setButton(true);
                  }}
                >
                  {"Compre Agora e Surpreenda!"}
                </ButtonMedita>
              </div>
            </div>
          </SectionMain>
        </SectionMain>
      </SectionMain>
    </div>
  );
};

export default SectionOne;
