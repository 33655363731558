import React, { useEffect, useRef, useState } from "react";
import SectionThree from "./sections/SectionThree";
import SectionFour from "./sections/SectionFour";
import SectionOne from "./sections/SectionOne";
import SectionTwo from "./sections/SectionTwo";
import SectionFive from "./sections/SectionFive";
import FooterSection from "../../home/desktop/sections/FooterSection";
import GiftCardPage from "../../../pages/giftcard";

const GiftCardScreenDesktop = () => {
  const [buttonClick, setButton] = useState(false);
  const myRef = useRef(null);
  useEffect(() => {
    if (buttonClick && myRef?.current) {
      myRef.current.scrollIntoView({ block: "start", behavior: "smooth" });
      setButton(false);
    }
  }, [buttonClick]);
  return (
    <div style={{ overflow: "hidden", marginBottom: 70 }}>
      <SectionOne setButton={setButton} />
      <SectionTwo setButton={setButton} />
      <SectionThree />
      <SectionFour />
      <SectionFive />
      <div ref={myRef}></div>
      <GiftCardPage />
      <FooterSection />
    </div>
  );
};

export default GiftCardScreenDesktop;
