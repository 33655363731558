import React, { useEffect, useState } from "react";
import simbolo from "../../../assets/images/home/simbolo.png";
import { Avatar, IconButton, makeStyles } from "@material-ui/core";
import ButtonMedita from "../../ButtonMedita";
import { useSnackbar } from "notistack";
import api from "../../../core/api";
import { ArrowForward, Check } from "@material-ui/icons";
import SubscriptionFilters from "./SubscriptionFilters";

const useStyles = makeStyles({
  leftSectionTwo: {
    height: "100%",
    margin: "auto",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  viewFilter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginLeft: "4%",
    marginRight: "4%",
    marginBottom: "5%",
  },
  view: {
    width: "75%",
    margin: "auto",
    marginTop: "50px",
    marginBottom: "50px",
  },
  avatar: {
    width: "64px",
    height: "64px",
    borderRadius: "16px",
  },
  premiumPage: {
    alignItems: "center",
    borderRadius: "1px",
    backgroundColor: "#fff",
    margin: "10px",
    paddingTop: "30px",
    position: "relative",
  },
  topArea: {
    height: "30px",
    marginLeft: "10px",
    marginRight: "10px",
  },
  topLinearGradient: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    borderTopLeftRadius: 1,
    borderTopRightRadius: 1,
    background: "#6eb64b",
    background: "linear-gradient(-90deg, #6eb64b 0.00%, #009daa 100.00%)",
  },
  topText: {
    fontFamily: "Century Gothic",
    color: "#fff",
    fontSize: 16,
    marginTop: 1,
    textAlign: "center",
    padding: 10,
  },
  typeArea: {
    height: "15%",
    borderTopLeftRadius: "1%",
    borderTopRightRadius: "1%",
    display: "flex",
    flexDirection: "column",
    paddingVertical: 10,
    justifyContent: "center",
    alignItems: "center",
  },
  discountBanner: {
    position: "absolute",
    top: 30,
    right: 0,
    width: "auto",
    height: "4%",
    background: "#6eb64b",
    background: "linear-gradient(-90deg, #6eb64b 0.00%, #009daa 100.00%)",
    paddingLeft: "5px",
    paddingRight: "5px",
  },
  bannerLinearGradient: {
    height: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  offerPercentText: {
    color: "#fff",
    fontSize: 20,
    marginTop: "0.5%",
  },
  typeText: {
    fontFamily: "Century Gothic",
    fontSize: 25,
    opacity: 1,
    letterSpacing: 1.5,
    color: "#6D6E70",
    fontWeight: "normal",
    marginTop: "3%",
  },
  titleAndValueArea: {
    display: "flex",
    flexDirection: "row",
  },
  titleText: {
    fontFamily: "Century Gothic",
    fontSize: 10,
    opacity: 1,
    textAlign: "left",
    letterSpacing: 1.5,
    color: "#6eb64b",
    fontWeight: "normal",
  },
  valueText: {
    fontFamily: "Century Gothic",
    fontSize: 30,
    opacity: 1,
    textAlign: "left",
    letterSpacing: 1.5,
    color: "#6eb64b",
    fontWeight: "700",
  },
  frequencyView: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  frequencyText: {
    fontFamily: "Century Gothic",
    fontSize: 10,
    opacity: 1,
    textAlign: "left",
    letterSpacing: 1.5,
    color: "#6eb64b",
    fontWeight: "normal",
  },
  viewArea: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "flex-end",
    paddingTop: "15px",
    width: "85%",
    margin: "auto",
    borderBottom: "1px solid #E5EAF4",
    paddingBottom: "30px",
    marginBottom: "15px",
  },
  titleArea: {
    fontFamily: "Century Gothic",
    fontSize: 16,
    fontWeight: "normal",
    fontStyle: "normal",
    textAlign: "left",
    color: "#5a7184",
  },
  viewDescription: {
    paddingTop: "15px",
    width: "85%",
    margin: "auto",
    paddingBottom: "15px",
    marginBottom: "15px",
  },
  viewNotice: {
    backgroundColor: "#F3FAF7",
    padding: 15,
    borderRadius: 15,
    marginBottom: "4%",
  },
  titleNotice: {
    fontFamily: "Century Gothic",
    fontSize: 13,
    fontWeight: "normal",
    fontStyle: "normal",
    textAlign: "center",
    color: "#183B56",
  },
  listItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    marginBottom: "3%",
  },
  titleAreaList: {
    fontFamily: "Century Gothic",
    fontSize: 14,
    fontWeight: "normal",
    fontStyle: "normal",
    textAlign: "left",
    color: "#5a7184",
    marginTop: "1%",
    width: "60%",
  },
  textItem: {
    fontFamily: "Century Gothic",
    fontSize: 15,
    fontWeight: "bold",
    fontStyle: "normal",
    textAlign: "left",
    color: "#183b56",
  },
  button: { width: "65%" },
  icon: { color: "#fff" },
  iconTwo: { color: "#000" },
  bold: { fontWeight: "bold" },
});

const CardsRegister = ({ setSelectPlan, setFormStep, plans, statusPlan }) => {
  const token = localStorage.getItem("token");
  const plano = localStorage.getItem("plano");
  const [subsSite, setSubsSite] = useState(true);
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();
  const snackbar = (message) => {
    enqueueSnackbar(`${message}`, {
      variant: "error",
      anchorOrigin: {
        horizontal: "right",
        vertical: "top",
      },
    });
  };

  const getSubscrition = async () => {
    const id = localStorage.getItem("id");
    if (id) {
      try {
        await api.get(`/subscriptions/users/${id}`).then(async ({ data }) => {
          if (data.transaction && data.transaction?.platform !== "site") {
            setSubsSite(false);
          }
        });
      } catch (e) {
        // snackbar("Ops.. algo de errado aconteceu. Tente novamente mais tarde!");
      }
    }
  };

  useEffect(() => {
    getSubscrition();
  }, []);

  const [planActive, setPlan] = useState(
    plano === "M" ? 1 : plano === "Y" ? 2 : 0
  );

  return (
    <div className={classes.view}>
      <div className={classes.viewFilter}>
        <SubscriptionFilters
          onSelectFilter={setPlan}
          selectedPlan={planActive}
        />
      </div>
      <div className={classes.leftSectionTwo}>
        {[plans[planActive]].map((plan) => (
          <div style={{ marginTop: !plan.showDiscountBanner ? "30px" : 0 }}>
            {plan.showDiscountBanner ? (
              <div className={classes.topArea}>
                <div className={classes.topLinearGradient}>
                  <span className={classes.topText}>MAIS POPULAR</span>
                </div>
              </div>
            ) : null}
            <div className={classes.premiumPage}>
              <div className={classes.typeArea}>
                {plan.showDiscountBanner ? (
                  <div className={classes.discountBanner}>
                    <div className={classes.bannerLinearGradient}>
                      <span className={classes.offerPercentText}>
                        {plan.percentage}% OFF
                      </span>
                    </div>
                  </div>
                ) : null}
                <Avatar
                  className={classes.avatar}
                  alt={`icon-simbolo`}
                  src={simbolo}
                />
                <span className={classes.typeText}>
                  medita
                  <span style={{ color: "#009DAA" }}>{plan.two_name}</span>
                </span>
                <div className={classes.titleAndValueArea}>
                  <span className={classes.titleText}>R$</span>
                  <div className={classes.frequencydiv}>
                    <span className={classes.valueText}>
                      {plan.price ? plan.price : "0,00"}
                    </span>
                    <span className={classes.frequencyText}>
                      {plan.frequency ? `/${plan.frequency}` : null}
                    </span>
                  </div>
                </div>
              </div>
              <div className={classes.viewArea}>
                <span className={classes.titleArea}>{plan.description}</span>
              </div>
              <div className={classes.viewDescription}>
                {plan.items.map((item, index) => (
                  <div className={classes.listItem} key={index}>
                    <Check
                      style={{
                        fontSize: 15,
                        borderRadius: 10,
                        padding: 2,
                        color: "#36B37E",
                        backgroundColor: "#E1F4EC",
                        marginRight: "5px",
                      }}
                    />

                    <div>
                      <span className={classes.textItem}>{item.title}</span>
                      <br />
                      <span className={classes.titleAreaList}>
                        {item.description}
                      </span>
                    </div>
                  </div>
                ))}
              </div>

              <div className={classes.viewDescription}>
                {plan.notice && (
                  <div className={classes.viewNotice}>{plan.notice}</div>
                )}

                <div
                  style={{
                    paddingTop: 20,
                    marginTop: 10,
                    borderRadius: 20,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {statusPlan !== "PENDING" &&
                    (!token ||
                      (plano !== "S" &&
                        plano !== "G" &&
                        ((plan.id === 1 && plan.activePlan) ||
                          plan.id !== 1))) && (
                      <ButtonMedita
                        width="auto"
                        variant={
                          plan.showDiscountBanner ? "contained" : "outlined"
                        }
                        className={classes.button}
                        disabled={plan.activePlan || (plan.id === 1 && !!token)}
                        onClick={() => {
                          if (!subsSite)
                            return snackbar(
                              "Acesse o Aplicativo Medita para concluir essa ação"
                            );
                          setSelectPlan(plan);
                        }}
                      >
                        {plan.activePlan ? (
                          "Seu Plano atual"
                        ) : (
                          <>
                            <div />
                            Escolher Plano
                            <IconButton
                              size="large"
                              className={
                                plan.showDiscountBanner
                                  ? classes.icon
                                  : classes.iconTwo
                              }
                            >
                              <ArrowForward />
                            </IconButton>
                          </>
                        )}
                      </ButtonMedita>
                    )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CardsRegister;
