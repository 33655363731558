import { makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import banner_first from "../../../assets/images/home/iphone.png";
import ButtonMedita from "../../../components/ButtonMedita";
import FooterSection from "../../../components/FooterSection";
import FormContact from "../../../components/FormContact";
import SectionMain from "../../../components/SectionMain";
import Title from "../../../components/Title";

const useStyles = makeStyles({
  container: { padding: "0px", justifyContent: "center" },
  containerLinks: { padding: "20px 4vw", marginBottom: "80px" },
  title: {
    flexDirection: "column",
    letterSpacing: 1,
    justifyContent: "space-between",
    display: "block",
    margin: "20px 0 10px",
  },
  titleWithMargin: {
    margin: "0 0 30px",
    display: "block",
  },
  subTitle: {
    justifyContent: "space-between",
    padding: 10,
    height: "90px",
    margin: "0px 20px",
  },
  cursive: {
    color: "#008393",
  },
  filter: {
    width: "25%",
  },
  link: {
    color: "#212121",
    textDecorationLine: "none",
    fontSize: 14,
    fontWeight: "600",
    cursor: "pointer",
    width: "100%",
    borderBottom: "1px solid #eeee",
    padding: "10px 0",
  },
  button: {
    margin: "20px",
  },
  linkBar: {
    height: 3,
    position: "relative",
  },
  image: {
    margin: "0 auto 60px",
    height: "auto",
    width: "100%",
  },
  aboutTitle: {
    marginBottom: "20px",
    display: "block",
  },
});

const ContactPageMobile = ({ formikProps }) => {
  const classes = useStyles();

  //Apagar arquivos de origem
  const [filter, setFilter] = useState();
  const arrayFilters = ["Parcerias", "Sugestões", "Comercial", "Outros"];

  return (
    <SectionMain position="center" className={classes.container}>
      <SectionMain xs={12} className={classes.containerLinks}>
        <Title
          align="center"
          fontWeight="800"
          letterSpacing={2}
          className={classes.aboutTitle}
        >
          A MEDITA
        </Title>

        <Title align="left">
          Somos uma startup brasileira, animada, que inventa e se reinventa o
          tempo todo para oferecer bem-estar as pessoas. Para isso, usamos como
          ferramenta a tecnologia, mas defendemos a ideia de que celulares e
          tablets são apenas instrumentos e devem ser usados com moderação.
          Contradição? Nós achamos que não. Aqui, trabalhamos para que nossa
          comunidade possa usar o aparelhos para aprender coisas, mas buscamos
          conteúdos que estimulem a prática fora da tela, no dia-a-dia,
          experimentando, se movimentando, testando e vivenciando!
          <br />
          <br />
          Nós buscamos pessoas que, como nós, desejam, precisam e acreditam ser
          possível ter mais qualidade de vida, não importa onde estejam. Nós
          somos gratos pela oportunidade de aprender algo novo o tempo todo com
          os professores, autores e especialistas que colaboram com o nosso
          aplicativo. Somos gratos pela generosidade de cada pessoa que
          participa dos nossos projetos e sonhos.
          <br />
          <br />
          Nosso compromisso? Continuar aprendendo sempre, entender o que você
          busca para se sentir bem no dia-a-dia e fazer o nosso melhor para
          levar isso até você. Ah, no meio dessa jornada tem muita evolução,
          erros e acertos, risadas, dias corridos e mestres incríveis que
          trabalham com bem-estar há muito tempo e acolhem com carinho e
          compreensão esse nosso início.
          <br />
          <br />
          E agora tem você, que está lendo tudo isso.
          <br />
          <br />
          Muito obrigado :)
        </Title>
      </SectionMain>

      <SectionMain item xs={6}>
        <img
          src={banner_first}
          className={classes.image}
          alt="Medita app frame mobile"
        />
      </SectionMain>

      <SectionMain xs={12} className={classes.title}>
        <Title
          fontWeight="800"
          size="medium"
          align="center"
          capitalize="uppercase"
          letterSpacing={2}
        >
          VAMOS CONVERSAR?
        </Title>
      </SectionMain>

      <SectionMain xs={12} className={classes.subTitle}>
        <Title variant="secondary" align="center">
          Você tem alguma pergunta ou sugestão? Envie aqui e responderemos em
          breve.
        </Title>
      </SectionMain>

      <SectionMain className={classes.container} md={6}>
        <FormContact
          className={classes.form}
          formikProps={formikProps}
          mobile
        />
      </SectionMain>

      <SectionMain className={classes.container}>
        <ButtonMedita
          width="180px"
          variant="outlined"
          className={classes.button}
          onClick={formikProps.handleSubmit}
        >
          {"Enviar"}
        </ButtonMedita>
      </SectionMain>

      <SectionMain>
        <FooterSection mobile hideNewsletter />
      </SectionMain>
    </SectionMain>
  );
};

export default ContactPageMobile;
