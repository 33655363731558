import React from "react";
import SectionMain from "../../components/SectionMain";
import { Hidden } from "@material-ui/core";
import GiftCardDesktop from "./desktop";
import GiftCardMobile from "./mobile";

const GiftCardPage = () => {
  return (
    <SectionMain
      noPadding
      position="center"
      style={{
        background: "#F4F4F4",
      }}
    >
      <Hidden only={["xs", "sm"]}>
        <GiftCardDesktop />
      </Hidden>
      <Hidden only={["md", "lg", "xl"]}>
        <GiftCardMobile />
      </Hidden>
    </SectionMain>
  );
};

export default GiftCardPage;
