import React from "react";
import "./style.css";
import "./styleCard.css";
import { makeStyles } from "@material-ui/core";
import ButtonMedita from "../ButtonMedita";

const useStyles = makeStyles({
  border: {
    marginRight: 10,
    borderRadius: 10,
  },
  form: {
    width: "80%",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: "20px",
  },
  p: {
    fontWeight: "600",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  pSpan: {
    fontWeight: "600",
    marginBottom: 0,
    textAlign: "left",
  },
  pInfos: {
    fontWeight: "300",
    marginBottom: 0,
    textAlign: "center",
    color: "#030102",
    fontSize: "12px",
    padding: 25,
  },
  stepCard: {
    display: "flex",
    marginTop: "15px",
  },
  modalCancel: {
    width: "500px",
    background: "#fff",
    margin: "200px auto",
    borderRadius: "13px",
    padding: "20px",
    textAlign: "center",
  },
  titleNotice: {
    fontFamily: "Century Gothic",
    fontSize: 13,
    fontWeight: "normal",
    fontStyle: "normal",
    textAlign: "center",
    color: "#183B56",
  },
  bold: { fontWeight: "bold" },
  containerSeven: {
    paddingBottom: "5%",
    position: "absolute",
    left: 0,
  },
  leftSectionSeven: {
    maxWidth: "75%",
    margin: "auto",
    marginTop: 50,
  },
  titleSectionSeven: {
    fontFamily: "Century Gothic",
    fontSize: "24px",
    fontWeight: "bold",
    fontStyle: "normal",
    textAlign: "center",
    color: "#000",
    marginTop: 10,
  },
  descriptionSeven: {
    fontFamily: "Century Gothic",
    fontSize: "18px",
    fontWeight: "normal",
    fontStyle: "normal",
    textAlign: "left",
    color: "#5a7084",
  },
  text: {
    fontFamily: "Century Gothic",
    fontSize: "18px",
    fontWeight: "bold",
    fontStyle: "normal",
    textAlign: "left",
    color: "#000",
  },
  sealView: {
    paddingLeft: 20,
    paddingRight: 20,
    backgroundColor: "#E1F3EC",
    borderRadius: 40,
    width: "fit-content",
    margin: "auto",
  },
  sealText: {
    fontFamily: "Century Gothic",
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
    textAlign: "center",
    color: "#36B37E",
  },
  card: {
    height: "200px",
    width: "250px",
    backgroundImage: ({ thumbnail }) => `url(${thumbnail})`,
    borderRadius: 30,
    backgroundSize: "cover",
    backgroundPosition: "center",
    justifyContent: "flex-end",
    flexDirection: "column",
  },
  cardBigger: {
    height: "260px",
    width: "300px",
    backgroundImage: ({ thumbnail }) => `url(${thumbnail})`,
    borderRadius: 30,
    backgroundSize: "cover",
    backgroundPosition: "center",
    justifyContent: "flex-end",
    flexDirection: "column",
  },
});

const StepThreeCard = ({ form, nextStep, prevStep }) => {
  const classes = useStyles();

  const FormatData = (title, text) => (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginTop: 20,
      }}
    >
      <span className={classes.text}>{title}:</span>
      <span className={classes.descriptionSeven}>{text}</span>
    </div>
  );

  return (
    form && (
      <div style={{ width: "100%", height: "100%" }}>
        <div className={classes.leftSectionSeven}>
          <div className={classes.titleSectionSeven}>
            Resumo de Compra
            <br />
            <span className={classes.descriptionSeven}>Vale Presente</span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 10,
            }}
          >
            <img
              src={form.card_image}
              height={200}
              alt="icon-card"
              className={classes.border}
            />
          </div>
          {/* <span
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 10,
          }}
          className={classes.descriptionSeven}
        >
          Link de Resgate: https://jjsdk.com
        </span> */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              marginTop: 20,
              marginBottom: 30,
            }}
          >
            {FormatData("De", form?.nameUser)}
            {FormatData("Para", form?.name)}
            {FormatData("Plano", form?.description)}
            {FormatData("Validade", "365 após a data de ativação")}
            {FormatData("Valor", form?.value)}
          </div>
          <div style={{ margin: "auto", width: "90%" }}>
            <ButtonMedita type="button" size="large" onClick={nextStep}>
              Realizar Pagamento
            </ButtonMedita>
            <ButtonMedita
              variant="outlined"
              type="button"
              onClick={prevStep}
              size="large"
              style={{ marginTop: "10px" }}
            >
              Cancelar
            </ButtonMedita>
          </div>
        </div>
      </div>
    )
  );
};

export default StepThreeCard;
