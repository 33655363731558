import { makeStyles } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import ButtonMedita from "../../ButtonMedita";

const SubscriptionFilters = ({ selectedPlan, onSelectFilter }) => {
  const subscriptionFilters = [
    {
      index: 0,
      name: "Free",
      type: "FREE_PLAN",
    },
    {
      index: 1,
      name: "Bem+",
      type: "MONTHLY_PLAN",
    },
    {
      index: 2,
      name: "Sempre",
      type: "YEAR_PLAN",
    },
  ];

  const [selectedFilter, setSelectedFilter] = useState(selectedPlan);

  useEffect(() => {
    onSelectFilter(selectedFilter);
  }, [onSelectFilter, selectedFilter]);

  return subscriptionFilters.map((subscriptionFilter, i) => (
    <button
      style={{
        backgroundColor: "#fff",
        borderWidth: 1,
        borderColor: "#eee",
        borderRadius: 20,
        paddingVertical: "1%",
        width: "30%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      onClick={() => {
        console.log(subscriptionFilter.index);
        setSelectedFilter(subscriptionFilter.index);
      }}
    >
      <span
        style={{
          fontSize: 10,
          letterSpacing: 0.5,
          color: "#6D6E70",
          textAlign: "center",
          fontFamily: "Century Gothic",
          fontSize: 10,
          opacity: 0.5,
          paddingHorizontal: "4%",
          fontWeight:
            selectedFilter === subscriptionFilter.index ? "700" : "normal",
          opacity: selectedFilter === subscriptionFilter.index ? 1 : 0.5,
        }}
      >
        medita
        <span style={{ color: "#009DAA" }}>{subscriptionFilter.name}</span>
      </span>
    </button>
  ));
};

export default SubscriptionFilters;
