import { IconButton, makeStyles } from "@material-ui/core";
import React from "react";
import Modal from "react-modal";
import SectionMain from "../../SectionMain";
import { Close } from "@material-ui/icons";
import ButtonMedita from "../../ButtonMedita";
import useGoToPage from "../../../hooks/useGoToPage";

const useStyles = makeStyles({
  planButton: {
    width: "100% !important",
    alignSelf: "center",
    marginLeft: "auto",
    marginRight: "auto",
  },

  modal: {
    width: "500px",
    background: "#fff",
    margin: "200px auto",
    borderRadius: "13px",
    padding: "20px",
    background: "#eee",
    textAlign: "center",
  },
  modalTitle: {
    fontSize: 15,
    marginBottom: 20,
    textAlign: "center",
    width: "100%",
  },
  modalDeleteTitle: {
    fontSize: 20,
    marginBottom: 20,
    textAlign: "center",
    width: "100%",
  },
  p: {
    padding: 0,
    margin: 5,
    fontWeight: "bold",
    color: "#454545",
  },
  formMobile: {
    width: "auto",
    padding: 20,
  },
  buttonEnviar: {
    textTransform: "inherit",
    width: "247px !important",
  },
  register: {
    color: "red",
    cursor: "pointer",
    padding: "14px 20px !important",
    fontWeight: "600 !important",
    letterSpacing: "1px",
    textTransform: "uppercase",
    textDecorationLine: "none",
    margin: "10px 0 15px 5px",
    borderRadius: "30px !important",
    width: "300px !important",
    height: "50px !important",
  },

  text: {
    fontSize: "16px",
    width: "50px",
    color: "red",
    fontWeight: "bold",
  },
});

const ModalInfoPlan = ({ modalIsOpen, setIsOpen }) => {
  const goToPage = useGoToPage();

  function closeModal() {
    setIsOpen(false);
  }

  const classes = useStyles();

  return (
    <SectionMain noPadding>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Cancelar Assinatura"
        className={classes.modal}
      >
        <div style={{ display: "flex" }}>
          <IconButton size="large" onClick={closeModal}>
            <Close />
          </IconButton>
        </div>
        <h2 className={classes.modalDeleteTitle}>
          A pessoa que você deseja presentear já tem o app MeditaBem.{" "}
        </h2>

        <p>Deseja cancelar a compra ou continuar para escolher outra pessoa?</p>

        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <ButtonMedita type="button" onClick={closeModal}>
            CONTINUAR
          </ButtonMedita>
          <ButtonMedita
            variant="outlined"
            type="button"
            onClick={() => {
              goToPage("/");
            }}
          >
            CANCELAR
          </ButtonMedita>
        </div>
      </Modal>
    </SectionMain>
  );
};

export default ModalInfoPlan;
