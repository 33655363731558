import api from "../core/api";
import { useEffect, useState } from "react";

const useGetPlans = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [plans, setPlans] = useState([]);

  const refetch = async () => {
    setIsLoading(true);
    await api
      .get("plans")
      .then(({ data }) => {
        setPlans(
          data.map((plan) => {
            const price = plan?.value?.toString()?.split(".");
            const value =
              price?.length === 1
                ? `${plan?.value},00`
                : price[1]?.length === 1
                ? `${plan?.value?.toString()?.replace(".", ",")}0`
                : `${plan?.value?.toString()?.replace(".", ",")}`;
            return {
              ...plan,
              value,
            };
          })
        );
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    refetch();
  }, []);

  return {
    plans,
    refetch,
    isLoading,
  };
};

export default useGetPlans;
