import { makeStyles } from "@material-ui/core";
import React from "react";
import center from "../../../../assets/images/giftcards/cel.png";
import SectionMain from "../../../../components/SectionMain";

const useStyles = makeStyles({
  container: {
    minHeight: "750px",
    marginTop: "20px",
    position: "relative",
  },
  banner: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "auto",
    marginTop: 20,
    marginBottom: 20,
  },
  viewImage: {
    maxHeight: "700px",
    pointerEvents: "none",
    right: "15%",
    bottom: "-10%",
  },
  leftSection: {
    width: "35%",
    marginLeft: "10%",
    display: "flex",
  },
  title: {
    fontFamily: "Century Gothic",
    fontSize: "18px",
    fontWeight: "bold",
    fontStyle: "normal",
    textAlign: "left",
    color: "#183b56",
  },
  titleTwo: {
    fontFamily: "Century Gothic",
    fontSize: "35px",
    fontWeight: "normal",
    fontStyle: "normal",
    textAlign: "left",
    color: "#36b37e",
  },
  description: {
    fontFamily: "Century Gothic",
    fontSize: "20px",
    fontWeight: "normal",
    fontStyle: "normal",
    textAlign: "left",
    color: "#5a7184",
  },
  root: {
    flexDirection: "column",
    alignItems: "flex-start",
    marginTop: 20,
    marginRight: 10,
    textAlign: "left",
  },
  avatar: {
    width: "64px",
    height: "64px",
    borderRadius: "16px",
    background: "#6eb64b",
  },
  titleItems: {
    fontFamily: "Century Gothic",
    fontSize: "20px",
    fontWeight: "bold",
    fontStyle: "normal",
    textAlign: "left",
    color: "#183b56",
    marginTop: 20,
  },
  descItems: {
    width: "85%",
    fontFamily: "Century Gothic",
    fontSize: "16px",
    fontWeight: "normal",
    fontStyle: "normal",
    textAlign: "left",
    color: "#5a7184",
  },
  align: {
    textAlign: "left",
    marginBottom: 20,
  },
  viewCenter: {
    display: "flex",
    alignItems: "center",
    marginTop: 10,
  },
  margin: { marginTop: 50 },
  color: { color: "#fff" },
});

const items = [
  "365 dias de acesso completo ao MeditaBem.",
  "Meditações guiadas para relaxamento, foco e equilíbrio emocional.",
  "Yoga e alongamentos guiados para corpo e mente.",
  "Playlists personalizáveis para criar momentos únicos.",
  "Sem anúncios para uma experiência 100% focada no bem-estar.",
];

const SectionFive = () => {
  const classes = useStyles();

  const token = localStorage.getItem("token");

  return (
    <SectionMain noPadding className={classes.container}>
      <SectionMain className={classes.banner}>
        <SectionMain className={classes.leftSection}>
          <div className={classes.description}>
            Dúvidas? Temos respostas!
            <br />
            <div>
              <span className={classes.titleTwo}>Perguntas frequentes:</span>
              <br />
              <br />
              <div style={{ width: "90%", marginTop: 10 }}>
                <span className={classes.description}>
                  <span className={classes.align}>
                    <b>Posso personalizar o vale presente?</b>
                    <br />
                    Sim! Você pode escolher entre nossos modelos exclusivos e
                    adicionar um toque especial ao presente.
                    <br />
                    <br />
                    <b>Como o presenteado ativa o vale-presente?</b>
                    <br />O presenteado receberá um e-mail com as instruções
                    completas para ativar sua assinatura no app MeditaBem. É
                    simples e rápido!
                    <br />
                    <br />
                    <b>O que está incluído na assinatura? </b>
                    <br />A assinatura inclui 365 dias de acesso completo ao
                    MeditaBem, com meditações guiadas, yoga, playlists
                    personalizáveis e uma experiência livre de anúncios.
                  </span>
                </span>
              </div>
            </div>
          </div>
        </SectionMain>
        <img src={center} className={classes.viewImage} />
      </SectionMain>
    </SectionMain>
  );
};

export default SectionFive;
