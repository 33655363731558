import React, { Component, useContext, useEffect, useState } from "react";
import "./style.css";
import ButtonMedita from "../ButtonMedita";
import { Button, makeStyles } from "@material-ui/core";
import CodeInput from "../CodeInput";
import { AuthContext } from "../../store/AuthContext";
import { useSnackbar } from "notistack";
import api from "../../core/api";

const useStyles = makeStyles({
  p: {
    fontWeight: "600",
    marginTop: "15px",
    marginBottom: "20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingLeft: "5rem",
    paddingRight: "5rem",
  },
  pContainer: {
    paddingLeft: "5rem",
    paddingRight: "5rem",
  },
  pTitleSpan: {
    fontWeight: "600",
    marginBottom: 0,
    textAlign: "left",
  },
  pSpan: {
    fontWeight: "600",
    marginBottom: 0,
    textAlign: "left",
    color: "#b9b9b9",
  },
  pTime: {
    width: "100%",
    display: "flex",
    margin: "1rem 0",
    justifyContent: "space-between",
    alignItems: "center",
  },
  pTimeLine: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
});

const VerifyCode = ({ formUser, nextStep }) => {
  const { setToken, setData } = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();
  const [code, setCode] = useState("");
  const [codeExp, setCodeExp] = useState(false);
  const [showResendEmail, setShowResendEmail] = useState(false);
  const [countdownTimer, setcountdownTimer] = useState();

  const GetCode = async () => {
    // await api.post(`auth/send-code/${formUser.id}`);
    await api.post(`auth/send-code/${formUser.id}`);
    clearInterval(countdownTimer);
    setcountdownTimer(setInterval(timer, 1000));
    setTimeout(() => setShowResendEmail(true), 120000);
    setCodeExp(false);
    setShowResendEmail(false);
    setTimeout(() => {
      clearInterval(countdownTimer);
      setCodeExp(true);
      setShowResendEmail(true);
    }, 300000);
  };

  const Continue = async () => {
    try {
      const data = await api.post(`auth/active-account/${formUser.id}`, {
        code,
      });

      if (data.status === 200) {
        localStorage.setItem("token", data.data.token);
        localStorage.setItem("id", data.data.user.id);
        localStorage.setItem("name", data.data.user.name);
        localStorage.setItem("plano", data.data.user.premium);
        localStorage.setItem("nickName", data.data.user.nickName);
        localStorage.setItem("email", data.data.user.email);
        localStorage.setItem("avatarUrl", data.data.user.avatarUrl);

        setToken(data.data.token);
        setData(data.data.user);

        nextStep();
      }
    } catch (e) {
      console.error({ e });

      enqueueSnackbar(`${e.response.data.error}` || "Ocorreu um erro", {
        variant: "error",
        anchorOrigin: {
          horizontal: "right",
          vertical: "top",
        },
      });
      return false;
    }
  };

  var upgradeTime = 300; // seconds
  var seconds = upgradeTime;

  function timer() {
    var days = Math.floor(seconds / 24 / 60 / 60);
    var hoursLeft = Math.floor(seconds - days * 86400);
    var hours = Math.floor(hoursLeft / 3600);
    var minutesLeft = Math.floor(hoursLeft - hours * 3600);
    var minutes = Math.floor(minutesLeft / 60);
    var remainingSeconds = seconds % 60;

    function pad(n) {
      return n < 10 ? "0" + n : n;
    }
    if (document.getElementById("timer"))
      document.getElementById("timer").innerHTML =
        pad(minutes) + ":" + pad(remainingSeconds);
    if (seconds === 0) {
      clearInterval(countdownTimer);
      document.getElementById("timer").innerHTML = "Completed";
      document.getElementById("reset").style.visibility = "hidden";
    } else {
      seconds--;
    }
  }

  useEffect(() => {
    localStorage.removeItem("idActive");
    localStorage.removeItem("emailActive");
    localStorage.removeItem("passActive");
    GetCode();
  }, []);

  const classes = useStyles();
  return (
    <div
      style={{
        width: "100%",
        maxWidth: "590px",
        display: "flex",
        flexDirection: "column",
        paddingLeft: "10%",
        paddingRight: "10%",
      }}
    >
      <div>
        <p className={classes.pSpan}>
          Digite o código que foi enviado para o seu email:
        </p>
        <p className={classes.pTitleSpan}>{formUser.email}</p>

        <div className={classes.pTimeLine}>
          <span className={classes.pSpan}>Seu código expira em: </span>
          {codeExp ? (
            <span style={{ color: "red", paddingLeft: 10 }}>
              {" "}
              Código expirado!
            </span>
          ) : (
            <div className="countdown">
              <div style={{ paddingLeft: 10 }}>
                <span id="timer">05:00</span>
              </div>
            </div>
          )}
        </div>
        <Button
          type="button"
          style={{ float: "left" }}
          onClick={GetCode}
          disabled={!showResendEmail}
        >
          Reenviar código
        </Button>
      </div>

      <CodeInput onChange={setCode} />

      <div>
        <p className={classes.pSpan}>
          A verificação do seu email aumenta a segurança da conta e ajuda você a
          receber comunicações importantes da{" "}
          <span className={{ fontWeight: 700 }}>MeditaBem</span> .
          <p className={classes.pSpan}>
            Não recebeu nossa mensagem? Verifique sua caixa de spam.{" "}
          </p>
          <p className={classes.pSpan}>
            Para garantir o recebimento da liberação de acesso ao seu app
            MeditaBem, inclua nosso e-mail nos seus contatos. Obrigado.
          </p>
        </p>
      </div>

      <div>
        <ButtonMedita
          // disabled={formikProps.isSubmitting}
          type="button"
          onClick={Continue}
          size="large"
          style={{ marginTop: "10px" }}
        >
          Validar
        </ButtonMedita>
      </div>
    </div>
  );
};

export default VerifyCode;
